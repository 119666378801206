import React, { FC } from 'react';

interface ResourcesCardInput {
  img: string;
  title: string;
  description: string;
}

export const ResourceCard: FC<ResourcesCardInput> = ({ img, title, description }) => {
  return (
    <>
      <div className='flex flex-initial w-full h-full border border-gray-200 grow shadow-card'>
        <div className='flex flex-col w-full px-6 pt-6 pb-2'>
          <div className='grow'>
            <img src={img} alt={title} className='w-full h-full' />
          </div>
          <h1 className='text-2xl  font-futura text-primary'>{title}</h1>
          <p className='text-xl font-futura text-primary'>{description}</p>
        </div>
      </div>
    </>
  );
};
