import { Transition } from '@headlessui/react';
import { FC } from 'react';
import Teleport from '../../components/Teleport';
interface InputModal {
  children?: JSX.Element | JSX.Element[];
  show?: boolean;
  className?: string;
  closeable?: boolean;
  onClose?: () => void;
  centered?: boolean;
  crossDisplay?: string;
}
const Modal: FC<InputModal> = ({ children, show, className = 'rounded-lg shadow-xl', closeable = true, onClose, centered = true, crossDisplay }) => {
  const center = 'flex items-center justify-center overflow-y-auto sm:px-0';
  return (
    <Teleport to='body'>
      <Transition show={show} leave='duration-200'>
        <div className={['fixed z-50 inset-0 ', centered ? center : ''].join(' ')}>
          <Transition.Child
            enter='duration-300 ease-out'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='duration-200 ease-in'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div
              className='fixed inset-0 transition-all transform cursor-pointer '
              onClick={() => {
                closeable ? onClose?.() : null;
              }}
            >
              <div className='absolute inset-0 bg-gray-500 opacity-75 '></div>
              <div
                className={`${crossDisplay} absolute px-2 text-3xl text-white transform rotate-45 w-[2em] h-[2em] text-bottom
                cursor-pointer right-2 top-2 border-primary hover:border-white hover:text-primary z-50`}
              >
                +
              </div>
            </div>
          </Transition.Child>

          <Transition.Child
            className={['md:w-full md:mx-auto', className].join(' ')}
            enter='duration-300 ease-out'
            enterFrom='translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95'
            enterTo='translate-y-0 opacity-100 sm:scale-100'
            leave='duration-200 ease-in'
            leaveFrom='translate-y-0 opacity-100 sm:scale-100'
            leaveTo='translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95'
          >
            <div className={['mb-6 overflow-hidden transition-all transform sm:w-full sm:mx-auto '].join(' ')}>{children}</div>
          </Transition.Child>
        </div>
      </Transition>
    </Teleport>
  );
};
export default Modal;
