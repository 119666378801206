import { PaymentCard, PaymentCardBrand } from 'lib/types/customer_card';
import { FC } from 'react';
interface CreditDebitCardProps {
  card: PaymentCard;
  onClick?: () => void;
  highlight?: boolean;
}
const getBrandImage = (brand: PaymentCardBrand) => {
  switch (brand) {
    case 'visa':
      return '/images/visa_logo.png';
    case 'mastercard':
      return '/images/mastercard_logo.png';
    case 'american express':
      return '/images/americanexpress_logo.png';
    default:
      return '/images/unknown_credit_card_icon.png';
  }
};
export const CreditDebitCard: FC<CreditDebitCardProps> = ({ card, onClick, highlight = false }) => {
  const container: any = [
    'w-96 h-56 m-auto bg-red-100 rounded-xl relative text-white shadow-2xl transition-transform transform hover:scale-110 ',
    highlight ? ' border-2 border-primary ' : '',
  ];
  const image = 'relative object-cover w-full h-full rounded-xl';
  const cardContainer = 'w-full px-8 absolute top-8 flex flex-col justify-between';
  const CardName = 'flex justify-between items-center';
  return (
    <div className={container} onClick={() => (onClick ? onClick() : undefined)}>
      <img className={image} src='/images/credit_card.png' />
      <div className={cardContainer}>
        <div className={CardName}>
          <div className=''>
            <p className='font-light'>Nombre</p>
            <p className='font-medium tracking-widest'>{card.name}</p>
          </div>
        </div>
        <div className='pt-1'>
          <p className='font-light'>Numero de tarjeta</p>
          <p className='font-medium tracking-more-wider'>**** **** **** {card.last4}</p>
        </div>
        <div className='pt-6'>
          <div className='flex justify-between'>
            <div className=''>
              <p className='font-light text-xs'>Expiración</p>
              <p className='font-medium tracking-wider text-sm'>
                {card.exp_month}/{card.exp_year}
              </p>
            </div>
            <img className='w-auto h-11' src={getBrandImage(card.brand)} />
          </div>
        </div>
      </div>
    </div>
  );
};
