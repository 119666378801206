// import "./ContactForm.css";
import api from 'api/Api';
import Link from 'next/link';
import React from 'react';
import swal from 'sweetalert';

const ContactForm = ({ codigo }) => {
  const onSbumbite = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    api()
      .post('/contact/new', {
        first_name: formData.get('first_name'),
        last_name: formData.get('last_name'),
        email: formData.get('email'),
        phone: formData.get('phone'),
        message: formData.get('message'),
        codigo: codigo,
      })
      .then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'formSubmission',
          content_name: codigo,
          content_category: `inmuebles/${codigo}`,
        });
        swal({
          title: 'Se envio correctamente tu mensaje!',
          text: 'Nos pondremos en contacto contigo a la brevedad',
          icon: 'success',
          buttons: ['Aceptar'],
        });
      })
      .catch((err) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'formSubmissionFailed',
          content_name: codigo,
          content_category: `inmuebles/${codigo}`,
          content_error_message: err.message || err.response.message,
        });
        swal({
          title: 'Hubo un problema al enviar tu mensaje!',
          text: 'Verifica tus datos, o intenta más tarde. ',
          icon: 'error',
          buttons: ['Aceptar'],
        });
      });
  };
  return (
    <aside className='relative flex justify-center w-10/12 min-h-full mx-auto mb-2 asd-card'>
      <form
        className='relative flex flex-col flex-wrap items-center justify-center flex-1 my-2 border shadow-lg border-secondary rounded-3xl h-[fit-content] py-8 md:sticky md:top-2 bg-white'
        onSubmit={onSbumbite}
      >
        <input
          className='w-4/5 pl-2 m-2 text-sm border rounded-lg outline-none md:text-base border-secondary h-9'
          type='text'
          placeholder='Nombre'
          name='first_name'
          required
        />
        <input
          className='w-4/5 pl-2 m-2 text-sm border rounded-lg outline-none md:text-base border-secondary h-9'
          type='text'
          placeholder='Apellido'
          name='last_name'
          required
        />
        <input
          className='w-4/5 pl-2 m-2 text-sm border rounded-lg outline-none md:text-base border-secondary h-9'
          type='email'
          placeholder='Email'
          name='email'
          required
        />
        <input
          className='w-4/5 pl-2 m-2 text-sm border rounded-lg outline-none md:text-base border-secondary btn-noarrows h-9'
          type='number'
          placeholder='WhatsApp'
          name='phone'
          required
        />
        <textarea
          className='w-4/5 h-16 pt-2 pl-2 m-2 text-sm border rounded-lg outline-none resize-none md:text-base no-scrollbar border-primary'
          name='message'
          defaultValue='Hola! Me interesa este inmueble, antes de visitarlo me gustaría saber…'
        ></textarea>
        <input
          className='w-4/5 h-10 m-2 text-sm text-white transition-all duration-100 outline-none cursor-pointer rounded-2xl bg-primary hover:text-lg'
          type='submit'
          value='Contactar'
        />
        <p className='justify-center mx-12 text-[0.6rem] text-gray-500'>
          Toda la información proporcionada está protegida de acuerdo con nuestra
          <Link href='/politicas/politicas-de-privacidad'>
            <a className='text-blue-300'>"Politica de privacidad"</a>
          </Link>
          . Al enviar tus datos aceptas nuestros
          <Link href='/politicas/terminos-y-condiciones'>
            <a className='text-blue-300'>"Terminos y Condiciones"</a>
          </Link>
          .
        </p>
      </form>
    </aside>
  );
};

export default ContactForm;
