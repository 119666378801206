import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';

const PageUnderConstruction = () => {
  const router = useRouter();
  const back =
    'p-2 text-white uppercase transition-all transform bg-black border-2 cursor-pointer hover:scale-110 bg-opacity-10 transparent hover:border-2 hover:border-black hover:border-opacity-30';
  const pageContainer = 'fixed inset-0 text-center bg-primary';
  const pageContainerChild = 'w-full my-[15%] mx-auto text-white';
  return (
    <div className={pageContainer}>
      <div className={pageContainerChild}>
        <div className='flex flex-col items-center justify-center md:flex-row min-h-content'>
          <div className='w-36 md:w-44'>
            <Image src='/images/LOGOSINFONDO blanco copy.png' width='1980' height='1371'></Image>
          </div>
          <h1 className='text-4xl leading-none uppercase md:text-6xl'>
            Website En
            <br />
            Construccion
          </h1>
        </div>
        {/* <Timer /> */}
        <div className='mt-6 md:mt-8'>
          <p className='my-5 text-sm'>¡Deseas regresar a la página anterior?</p>
          <button onClick={() => router.back()} className={back}>
            Regresar
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageUnderConstruction;
