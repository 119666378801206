import React, { FC } from 'react';

interface InputMeta {
  description?: string;
  title?: string;
  url?: string;
  t_title?: string;
  t_description?: string;
  t_site?: string;
  t_author?: string;
  og_image?: string;
  children?: JSX.Element;
}

const index: FC<InputMeta> = ({
  description = 'Sabemos que elegir dónde vivir es una decisión importante, por eso queremos que te sientas seguro y acompañado en el proceso. Permítenos ser tu concierge inmobiliario.',
  title = ' Odigós - Encuentra tu Espacio, Empieza tu busqueda con un concierge inmobiliario',
  url = 'https://odigos.mx',
  children,
  t_title,
  t_description,
  t_site,
  t_author,
  og_image,
}) => {
  return (
    <>
      <title>{title ? title : 'Odigós'}</title>
      <meta name='description' content={description} />
      <meta property='og:type' content='website' />
      <meta name='og:title' property='og:title' content={title ? title : 'Odigós'} />
      <meta name='og:description' property='og:description' content={description} />
      <meta property='og:site_name' content={title ? title : 'Odigós'} />
      <meta property='og:url' content={url} />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:title' content={t_title || title} />
      <meta name='twitter:description' content={t_description || description} />
      <meta name='twitter:site' content={t_site} />
      <meta name='twitter:creator' content={t_author} />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta name='theme-color' content='#000000' />
      <link rel='icon' type='image/png' href='/images/LOGOSINFONDO-replicado.png' />
      <link rel='apple-touch-icon' href='/images/LOGOSINFONDO-replicado.png' />

      <meta property='og:image' content={og_image || '/images/LOGOSINFONDO-replicado.png'} />
      <meta name='twitter:image' content={og_image || '/images/LOGOSINFONDO-replicado.png'} />
      <link rel='canonical' href='https://odigos.com.mx/' />
      {children}
    </>
  );
};

export default index;
