import React from 'react';

const YoutubeIcon = ({ ...props }) => {
  return (
    <svg width='60' height='50' viewBox='0 0 60 50' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M37.42 23.4L30.21 27.11L23 30.8V16L37.42 23.4Z' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M45.92 7H14.13C9.63992 7 6 10.6399 6 15.13V32.13C6 36.6201 9.63992 40.26 14.13 40.26H45.92C50.4101 40.26 54.05 36.6201 54.05 32.13V15.13C54.05 10.6399 50.4101 7 45.92 7Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default YoutubeIcon;
