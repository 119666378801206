import Api from 'api/Api';
export const queryStringEnconder = (selectedValues, multiSelectValues) => {
  let query1 = selectedValues
    ? Object.entries(selectedValues)
        .filter((x) => x[1] >= 0)
        .map((x) => x.join('='))
        .join('&')
    : '';

  let formatQuery2 = multiSelectValues
    ? multiSelectValues.reduce(
        (groups, item) => ({
          ...groups,
          [item.filter]: [...(groups[item.filter] || []), item.option],
        }),
        {}
      ) || {}
    : '';
  let query2 = Object.entries(formatQuery2)
    .map((item) => item[0] + '=' + item[1].join(','))
    .join('&');
  let url = query1 && query2 ? encodeURI([query2, query1].join('&')) : query1 ? encodeURI(query1) : encodeURI(query2);
  return url;
};

export const queryStringDecoder = (queryString) => {
  return decodeURI(queryString)
    .slice(1)
    .split('&')
    .map((item) => item.split('='));
};

export const handleImageError = (e) => {
  if (e.target.src === '/images/404imagen.jpg') return;
  e.target.src = '/images/404imagen.jpg';
};

/**
 * Parse a localized number to a float.
 * @param {string} stringNumber - the localized number
 * @param {string} locale - [optional] the locale that the number is represented in. Omit this parameter to use the current locale.
 */
export const parseLocaleNumber = (stringNumber, locale) => {
  let point = stringNumber.split().pop();
  if (point === '.') {
    return stringNumber;
  }
  let thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, '');
  let decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, '');

  return parseFloat(stringNumber.replace(new RegExp('\\' + thousandSeparator, 'g'), '').replace(new RegExp('\\' + decimalSeparator), '.')).toFixed(2);
};

export const getQueryFromObject = (object) => {
  let query = {};
  Object.entries(object).forEach(([name, values]) => {
    let itemValues = '';
    if (values && typeof values === 'object') {
      itemValues = Object.entries(values)
        ?.map(([name, value]) => (value ? name : null))
        ?.filter((x) => x)
        ?.join(',');
    } else {
      itemValues = values == '0' ? null : values;
    }
    if (itemValues) {
      query = { ...query, [name]: itemValues };
    }
  });
  return query;
};

export const prepopulateSearchBar = (setValue, query, setOptions) => {
  async function getData(search) {
    const { data } = await Api().get(`location/prefill?${search}`);

    setOptions(data);
  }
  getData(`estados=${query.estados}&alcaldias=${query.alcaldias}&colonias=${query.colonias}`);
  Object.entries(query)?.forEach(([name, value]) => {
    if (name === 'codigo') return setValue(name, value);
    if (+value) {
      setValue(name, +value);
      return;
    }
    if (['estados', 'alcaldias', 'colonias'].includes(name)) {
      setValue(name, value);
      return;
    }
    if (name == 'tipo_propiedad') return setValue(name, value);
    if (!value.includes(',')) {
      setValue(name, { [value]: true });
      return;
    }
    setValue(
      name,
      value.split(',').reduce((acc, curr) => ({ ...acc, [curr]: true }), {})
    );
  });
};
export const objectIsEmpty = (object) => object && Object.keys(object).length === 0 && object.constructor === Object;

export const formatNumber = (number, locale = 'en-US') => new Intl.NumberFormat(locale).format(number);

// a function that converts DMS coordinates like 19°23'42.2"N" to decimal degrees
export const dmsToDef = (coordinate) => {
  // split the coordinate into its components
  if (!coordinate) return null;
  const parts = coordinate.replace(/"[W|N]"/, '').split(/[°']/g);
  if (!parts.length === 3) return null;
  const degrees = parseInt(parts[0]);
  const minutes = parseInt(parts[1]);
  const seconds = parseFloat(parts[2]);

  // convert to decimal degrees
  const decimalDegrees = degrees + minutes / 60 + seconds / 3600;

  if (!decimalDegrees) return null;
  // return the decimal degrees
  return decimalDegrees;
};

export const sortAlphabetically = (a, b) => {
  a = a
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  b = b
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
