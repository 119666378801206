import React from 'react';

const PrincipalesAmenidades = ({ descripcion }) => {
  const listCont = 'flex flex-row justify-between -ml-1 text-sm font-bold list-none md:text-base flex-nowrap text-secondary';
  const li = 'flex pr-2 flex-21 text-secondary';
  return (
    <ul className={listCont}>
      <li className={li}>
        <img className='w-8 h-6' src='/SVG/M2 de Construcción.svg' alt='amenidades' />
        {descripcion && <p>{descripcion?.tamaño}m²</p>}
      </li>
      <li className={li}>
        <img className='w-8 h-6' src='/SVG/Habitaciones.svg' alt='amenidades' />
        {descripcion && <p>{descripcion?.recamaras}</p>}
      </li>
      <li className={li}>
        <img className='w-8 h-6' src='/SVG/Bano.svg' alt='amenidades' />
        {descripcion && <p>{descripcion?.baños}</p>}
      </li>
      <li className={li}>
        <img className='w-8 h-6' src='/SVG/Estacionamientos.svg' alt='amenidades' />
        {descripcion && <p>{descripcion?.estacionamientos}</p>}
      </li>
    </ul>
  );
};
export default PrincipalesAmenidades;
