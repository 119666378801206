import React from 'react';
import Image from 'next/image';
const Logo = ({ className, blanco = false, full = true }) => (
  <div className={className}>
    {full ? (
      blanco ? (
        <Image layout='intrinsic' width='2540' height='597' src='/images/Odigós Blanco.png' alt='Brand logo' priority className='cursor-pointer' />
      ) : (
        <Image layout='intrinsic' width='2540' height='597' src='/images/odigos azul.png' alt='Brand logo' priority className='cursor-pointer' />
      )
    ) : blanco ? (
      <Image
        layout='intrinsic'
        width='2540'
        height='597'
        src='/images/LOGOSINFONDO blanco copy.png'
        alt='Brand logo'
        priority
        className='cursor-pointer'
      />
    ) : (
      <Image layout='intrinsic' width='2540' height='597' src='/images/odigos azul.png' alt='Brand logo' priority className='cursor-pointer' />
    )}
  </div>
);
export default Logo;
