import { FC } from 'react';

// Rendering individual images
interface InputImage {
  image: any;
  isDeletable: any;
  onDelete: any;
  children: JSX.Element;
}
export const Image: FC<InputImage> = ({ image, isDeletable, onDelete, children }) => {
  const closeButton = 'absolute px-2 font-bold rounded-full cursor-pointer text-primary top-2 right-4 text-md bg-prmiary hover:bg-opacity-80';
  return (
    <div className={[' w-[calc(33%-20px)]  m-2 cursor-move transition-all duration-200', isDeletable && 'relative'].join(' ')}>
      <img alt={`imagen - ${image.descripcion}`} src={image.url} className='w-full h-64 border-[0.5px] border-primary rounded-lg' />
      {isDeletable && (
        <span className={closeButton} onClick={() => onDelete(image.id)}>
          X
        </span>
      )}
      {children}
    </div>
  );
};

// Image.Placeholder = () => {
//   return (
//     <div className='h-64 w-[calc(33%-20px)] border border-gray-300 m-2 cursor-move transition-all duration-200'>
//       <img alt={`imgplaceholder`} src={'/images/LoadingImagePlaceholder.jpg'} className='w-full h-full' />
//     </div>
//   );
// };

// ImageList Component
// Image.ImageList = ({ images, isDeletable = false, onDelete }) => {
//   // render each image by calling Image component
//   const renderImage = (image) => {
//     return <Image image={image} key={`${image.id}-image`} isDeletable={isDeletable} onDelete={onDelete} />;
//   };

//   // Return the list of files
//   return <section className='flex flex-wrap p-0 my-5 list-none'>{images.map(renderImage)}</section>;
// };

export default Image;
