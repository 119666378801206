import Api from 'api/Api';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';

const PopUpConcelarSuscripcion = ({ show, onClose, onCancel }) => {
  const [isBusy, setIsBusy] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const { getToken } = useAuth();
  const cancelSubscription = async () => {
    if (!isBusy)
      try {
        setIsBusy(true);
        const { data } = await Api(getToken()).post('memberships/cancelSubscription', {});
        if (data) setIsCanceled(true);
      } catch (e) {
      } finally {
        setIsBusy(false);
      }
  };

  const popUp = 'absolute mt-[25vh] msm:mt-[32vh] w-[100%] h-[70vh] z-40';
  const container =
    'block text-center bg-[#fff]  sm:w-[32em] msm:w-[90vw] h-[8em] msm:h-[13em] ml-auto mr-auto rounded-lg shadow-card border-[#80a8b383]  border-[1px] text-xl';
  const popUpHeader = 'flex items-center justify-center h-[2.3em] bg-primary text-lg rounded-tl-lg rounded-tr-lg';
  const HeaderSpan = 'text-[#fff] ml-auto mr-auto';
  const popClose = ' mr-[0.5em] text-[#fff]';
  const membershipStatus = 'text-center font-semibold text-sm msm:text-base text-primary';
  const buttonsContainer = 'relative float-left w-full h-auto mt-[1em] text-base msm:mt-[2em]';
  const backButton = 'bg-primary text-[#fff] rounded-xl mr-2 w-[13.5em] text-base py-[0.2em] px-6 shadow-card msm:ml-auto msm:mr-auto';
  const cancelSuscriptionButton = [
    ' rounded-xl text-base ml-2 w-[13.5em] py-[0.2em] px-6 shadow-card border-[1px] msm:ml-auto msm:mr-auto msm:mt-3 ',
    isBusy ? ' bg-gray-400 text-black border-gray-400 pointer-events-none' : ' bg-[#fff] text-primary border-primary ',
  ].join(' ');

  return show ? (
    <>
      <div className={popUp}>
        <div className={container}>
          <div className={popUpHeader}>
            <span className={HeaderSpan}>Cancelar Suscripción</span>
            <button className={popClose} onClick={!isBusy ? (!isCanceled ? onClose : onCancel) : undefined}>
              X
            </button>
          </div>
          <span className={membershipStatus}>
            {isCanceled
              ? 'Suscripción cancelada, perderas los beneficios de tu membresía al finalizar su periodo'
              : 'Si cancelas tu suscripción perderás acceso a los beneficios de tu membresía'}
          </span>

          <div className={buttonsContainer}>
            {!isBusy && (
              <button className={backButton} onClick={!isCanceled ? onClose : onCancel}>
                Regresar
              </button>
            )}
            {!isCanceled && (
              <button className={cancelSuscriptionButton} onClick={() => cancelSubscription()}>
                Cancelar Suscripción
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <div />
  );
};

export default PopUpConcelarSuscripcion;
