import { FC } from 'react';
import ReactPlayer from 'react-player';

interface MiniVideoCardProps {
  url: string;
  showVideoBorder?: boolean;
}
export const VideoPlayer: FC<MiniVideoCardProps> = ({ url, showVideoBorder }) => {
  const videoContainer = 'flex flex-col self-center w-full px-10 msm:w-[400px] md:w-[480px] md:px-0 xl:w-[720px] xl:px-0 2xl:w-[1024px] 2xl:px-0 ';
  const video: any = ['relative w-full  bg-gray-500', showVideoBorder ? 'border-black border-[1px]' : ''];
  return (
    <div className={videoContainer}>
      <div className={video} style={{ paddingTop: '56.25%' }}>
        <ReactPlayer style={{ position: 'absolute', left: 0, top: 0 }} url={url} controls={true} width='100%' height='100%' />
      </div>
    </div>
  );
};
