import axios from 'axios';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const Api = (auth = false) => {
  return auth
    ? axios.create({
        baseURL: publicRuntimeConfig.REACT_APP_API_LINK,
        withCredentials: true,
        headers: {
          authorization: `Bearer ${auth}`,
          Accept: 'application/json',
        },
      })
    : axios.create({
        baseURL: publicRuntimeConfig.REACT_APP_API_LINK,
        withCredentials: true,
        headers: {
          Accept: 'application/json',
        },
      });
};

export const ApiPdf = (id, auth) => {
  return {
    url: publicRuntimeConfig.REACT_APP_API_LINK + `/studio/resources/${id}/pdf`,
    httpHeaders: { authorization: `Bearer ${auth}` },
    withCredentials: true,
  };
};

export const MapApi = (address) => {
  return axios.create({
    baseURL: `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${publicRuntimeConfig.GEOCODE_API_KEY}`,
    headers: {
      Accept: 'application/json',
    },
  });
};
export default Api;
