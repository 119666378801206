import { useDropzone } from 'react-dropzone';

const Dropzone = ({ onDrop, accept, className, multiple }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple: false,
  });

  return (
    <div
      className={['p-8 h-[40vh] bg-primary bg-opacity-30 font-futura-med italic text-lg', isDragActive && 'bg-opacity-60'].join(' ')}
      {...getRootProps()}
    >
      <input className='dropzone-input' {...getInputProps()} multiple={false} />
      <div className='flex flex-col items-center justify-center h-full gap-2'>
        <div>
          <img src='/SVG/Imagenes.svg' className='w-16' alt='placeholder de imagenes' />
        </div>
        {isDragActive ? (
          <p className='text-white uppercase dropzone-content'>Sueltala!</p>
        ) : (
          <p className='text-white uppercase dropzone-content'>Agregar imágenes</p>
        )}
        <div className='text-xl text-primary px-2.5 uppercase bg-white rounded-full'>+</div>
      </div>
    </div>
  );
};

export default Dropzone;
