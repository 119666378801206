export const multiSelectOptions = [
  { group: 'Estados', filter: 'estados', option: 'Estado de México' },
  { group: 'Estados', filter: 'estados', option: 'Ciudad de México' },
  { group: 'Alcaldias', filter: 'alcaldias', option: 'Naucalpan de Juarez' },
  { group: 'Alcaldias', filter: 'alcaldias', option: 'Miguel Hidalgo' },
  { group: 'Alcaldias', filter: 'alcaldias', option: 'Huixquilucan' },
  { group: 'Alcaldias', filter: 'alcaldias', option: 'Cuajimalpa de Morelos' },
  { group: 'Alcaldias', filter: 'alcaldias', option: 'Naucalpan' },
  { group: 'Alcaldias', filter: 'alcaldias', option: 'Álvaro Obregón' },
  // { group: "Alcaldias", filter: "alcaldias", option: "Cuajimalpa" }, fix in the db
  // { group: "Alcaldias", filter: "alcaldias", option: "Hacienda de las Palmas" }, fix in the db "COLONIA"
  { group: 'Alcaldias', filter: 'alcaldias', option: 'Benito Juarez' },
  { group: 'Alcaldias', filter: 'alcaldias', option: 'Cuauhtemoc' },
  { group: 'Alcaldias', filter: 'alcaldias', option: 'Coyoacán' },
  { group: 'Colonias', filter: 'colonias', option: 'Valle de las Palmas' },
  { group: 'Colonias', filter: 'colonias', option: 'Bosques de las Lomas' },
  { group: 'Colonias', filter: 'colonias', option: 'Lomas del Sol' },
  { group: 'Colonias', filter: 'colonias', option: 'Lomas de la Herradura' },
  { group: 'Colonias', filter: 'colonias', option: 'Bosques de la Herradura' },
  { group: 'Colonias', filter: 'colonias', option: 'Lomas de Vista Hermosa' },
  { group: 'Colonias', filter: 'colonias', option: 'Lomas de Tecamachalco' },
  { group: 'Colonias', filter: 'colonias', option: 'Hacienda de las Palmas' },
  { group: 'Colonias', filter: 'colonias', option: 'Bosque de las Palmas' },
  { group: 'Colonias', filter: 'colonias', option: 'Jesús del Monte' },
  { group: 'Colonias', filter: 'colonias', option: 'Polanco' },
  { group: 'Colonias', filter: 'colonias', option: 'Lomas Country Club' },
  { group: 'Colonias', filter: 'colonias', option: 'Bosque Real' },
  { group: 'Colonias', filter: 'colonias', option: 'Lomas de Santa Fe' },
  { group: 'Colonias', filter: 'colonias', option: 'Palmas Altas' },
  { group: 'Colonias', filter: 'colonias', option: 'Santa Fe' },
  { group: 'Colonias', filter: 'colonias', option: 'Granjas Palo Alto' },
  { group: 'Colonias', filter: 'colonias', option: 'Lomas del Chamizal' },
  { group: 'Colonias', filter: 'colonias', option: 'Lomas de Chapultepec' },
  { group: 'Colonias', filter: 'colonias', option: 'Interlomas' },
  { group: 'Colonias', filter: 'colonias', option: 'El Molino' },
  { group: 'Colonias', filter: 'colonias', option: 'Lomas Anahuac' },
  { group: 'Colonias', filter: 'colonias', option: 'La Herradura' },
  { group: 'Colonias', filter: 'colonias', option: 'Lomas de las Palmas' },
  { group: 'Colonias', filter: 'colonias', option: 'San José de los Cedros' },
  { group: 'Colonias', filter: 'colonias', option: 'Parques de la Herradura' },
  { group: 'Colonias', filter: 'colonias', option: 'Bosques de las Palmas' },
  // { group: "Colonias", filter: "colonias", option: "Huixquilucan " }, FIX in the db ALCALDIA
  { group: 'Colonias', filter: 'colonias', option: 'Villa Florence' },
  { group: 'Colonias', filter: 'colonias', option: 'Lomas del Río' },
  { group: 'Colonias', filter: 'colonias', option: 'Lomas de Tarango' },
  { group: 'Operacion', filter: 'tipo_contrato', option: 'renta' },
  { group: 'Operacion', filter: 'tipo_contrato', option: 'venta' },
  { group: 'Operacion', filter: 'tipo_contrato', option: 'preventa' },
  { group: 'Inmueble', filter: 'tipo_propiedad', option: 'casa' },
  { group: 'Inmueble', filter: 'tipo_propiedad', option: 'oficina' },
  { group: 'Inmueble', filter: 'tipo_propiedad', option: 'departamento' },
];
export const selectOptions = [
  {
    filter: 'baños',
    selectLabel: 'Baños',
    options: [
      { label: '0 o más', value: 0 },
      { label: '1 o más', value: 1 },
      { label: '1.5 o más', value: 1.5 },
      { label: '2 o más', value: 2 },
      { label: '2.5 o más', value: 2.5 },
      { label: '3 o más', value: 3 },
      { label: '3.5 o más', value: 3.5 },
      { label: '4 o más', value: 4 },
      { label: '4.5 o más', value: 4.5 },
      { label: '5 o más', value: 5 },
    ],
  },
  {
    filter: 'recamaras',
    selectLabel: 'Recamaras',
    options: [
      { label: '0 o más', value: 0 },
      { label: '1 o más', value: 1 },
      { label: '1.5 o más', value: 1.5 },
      { label: '2 o más', value: 2 },
      { label: '2.5 o más', value: 2.5 },
      { label: '3 o más', value: 3 },
      { label: '3.5 o más', value: 3.5 },
      { label: '4 o más', value: 4 },
      { label: '4.5 o más', value: 4.5 },
      { label: '5 o más', value: 5 },
    ],
  },
  {
    filter: 'precio_max',
    selectLabel: 'Precio máximo',
    options: [
      { label: 'Hasta 20.000 MXP', value: 20000 },
      { label: 'Hasta 50.000 MXP', value: 50000 },
      { label: 'Hasta 100.000 MXP', value: 100000 },
      { label: 'Hasta 500.000 MXP', value: 500000 },
      { label: 'Hasta 1.000.000 MXP', value: 1000000 },
      { label: 'Hasta 10.000.000 MXP', value: 10000000 },
    ],
  },
  {
    filter: 'precio_min',
    selectLabel: 'Precio mínimo',
    options: [
      { label: 'Desde 20.000 MXP', value: 20000 },
      { label: 'Desde 50.000 MXP', value: 50000 },
      { label: 'Desde 100.000 MXP', value: 100000 },
      { label: 'Desde 500.000 MXP', value: 500000 },
      { label: 'Desde 1.000.000 MXP', value: 1000000 },
      { label: 'Desde 10.000.000 MXP', value: 10000000 },
    ],
  },
];
