// import Script from 'next/script';
// import { FC, useEffect } from 'react';
import { FC } from 'react';
import { useRecoilState } from 'recoil';
import { VideoPlayer } from '..';
import { userState } from '../../atoms/UserAtom';

interface VideoPlayerPopUpProps {
  videoId?: number;
  title: string;
  videoUrl: string;
  show: boolean;
  onClose: () => void;
  description?: string;
  assetName?: string;
  assetUrl?: string;
}
export const VideoPlayerPopUp: FC<VideoPlayerPopUpProps> = ({ videoId, title, description, videoUrl, assetName, assetUrl, show, onClose }) => {
  const [user, setUser] = useRecoilState(userState);

  if (show == true) {
    try {
      if (_learnq) {
        _learnq.push([
          'identify',
          {
            $email: user.email,
          },
        ]);
        const track = [
          'track',
          `Sessions video numero ${videoId}`,
          {
            $email: user.email,
            $first_name: user.name,
            $last_name: 'berardi33',
            clicked: 'on video sesions',
          },
        ];

        _learnq.push(track);
      }
      // console.log(_learnq, user.email);
      // console.log('resourses', resources[0].id);
    } catch (e) {}
    // console.log(show, videoId, user.email, 'cosas');
  }

  return show ? (
    <>
      <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
        <div className='relative w-auto mmd:w-full mmd:h-[100%] my-6 mmd:my-0 mx-auto'>
          {/*content*/}
          <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full mmd:h-[100%] bg-white outline-none focus:outline-none'>
            {/*header*/}
            <div className='flex items-center justify-between p-5 border-b border-solid rounded-t border-blueGray-200'>
              <h3 className='text-3xl font-futura-demi text-primary'>{title}</h3>
              <button
                className='px-6 py-2 mb-1 mr-1 text-sm text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent font-futura focus:outline-none'
                type='button'
                onClick={() => onClose()}
              >
                Cerrar
              </button>
            </div>
            {/* subheader */}
            {description && (
              <div className='flex items-start justify-between p-5'>
                <h4 className='text-2xl font-futura text-primary'>{description}</h4>
              </div>
            )}
            {/*body*/}
            <div className='relative flex-auto p-6'>
              <VideoPlayer url={videoUrl} />
            </div>
            {/* asset */}
            {assetName && assetUrl && (
              <div className='flex flex-col p-5'>
                <p className='pt-5 text-3xl text-center font-futura text-primary md:text-left'>{'Contenido Descargable'}</p>
                <DownloadArchive archiveName={assetName} archiveUrl={assetUrl} />
              </div>
            )}
          </div>
        </div>
        {/* <Script
          src='https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Rruh78'
          onLoad={() => {
            klaviyoVideo();
          }}
        /> */}
      </div>
      <div className='fixed inset-0 z-40 bg-black opacity-25'></div>
    </>
  ) : null;
};

const DownloadArchive = ({ archiveName, archiveUrl }) => {
  const downloadButton = 'rounded-lg leading-none text-md py-2.5 px-5 text-white uppercase bg-primary shadow-lg pointer-events-none';
  const archiveNameStyle = 'self-center flex-none font-futura text-primary';
  return (
    <div className='flex px-5 py-5'>
      <p className={archiveNameStyle}>{archiveName}</p>
      <div className='flex-grow' />
      <a href={archiveUrl} download target='_blank' rel='noopener noreferrer'>
        <button className={downloadButton}>Descargar</button>
      </a>
    </div>
  );
};
