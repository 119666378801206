import { FC } from 'react';

interface H1Props {
  content: string;
  className?: string;
}
export const H1: FC<H1Props> = ({ content, className }) => {
  return (
    <h1
      className={['text-5xl text-center font-futura-demi text-primary pt-20 uppercase', className].join(' ')}
      style={{ textShadow: '2px 2px 6px gray' }}
    >
      {content}
    </h1>
  );
};
