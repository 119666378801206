import { MembershipDeal, MiniVideoCard, Modal, ResourceCard } from 'components/';
import { Membership } from 'lib/types/membership';
import React, { FC } from 'react';
import { MiniVideoCardProps } from '../../components/MiniVideoCard';

interface PopUpInput {
  show: boolean;
  index: number;
  onClose?: () => void;
}

interface PopUpInfoInput extends PopUpInput {
  memberships: Membership[] | undefined;
}

interface ResourcesCardInput {
  img: string;
  title: string;
  description: string;
}

const tallerImages: string[] = [
  'https://storage.googleapis.com/fotos-odigos-dev/assets/undefined/Talleres6.jpg',
  'https://storage.googleapis.com/fotos-odigos-dev/assets/undefined/Talleres5.jpg',
  'https://storage.googleapis.com/fotos-odigos-dev/assets/undefined/Talleres4.jpg',
];

const DataResourcesCard: ResourcesCardInput[] = [
  {
    title: 'Guía de ingeniería inversa: ',
    description: 'Conoce la estrategia de facebook de tus competidores',
    img: 'https://storage.googleapis.com/fotos-odigos-dev/assets/undefined/Facebook%20Competencia.jpg',
  },
  {
    title: 'Newsletter 6 meses | Compradores: ',
    description: 'Flujo de correos electrónicos para convertir interesados en clientes',
    img: 'https://storage.googleapis.com/fotos-odigos-dev/assets/undefined/Newsletter%20Compradores.jpg',
  },
];

const DataPopUpSessions: MiniVideoCardProps[] = [
  {
    title: 'Perfil del Agente Inmobiliario',
    duration: '39:11',
    description: 'Sergio Felgueres - Presidente Remax México',
    thumbnail: 'https://storage.googleapis.com/fotos-odigos-dev/sessions-resources/prod/ThumbnailSergio.png',
    isPreview: true,
  },
  {
    title: 'Ciudades Inteligentes',
    duration: '27:34',
    description: 'Manuel Morales y Manuel Morales Jr. - Grupo Bosque Real',
    thumbnail: 'https://storage.googleapis.com/fotos-odigos-dev/sessions-resources/prod/ThumbnailManuel.png',
    isPreview: true,
  },
  {
    title: 'Interiorismo Funcional',
    duration: '33:07',
    description: 'Joshua Bornstein - Director de Grupo de Diseñadores',
    thumbnail: 'https://storage.googleapis.com/fotos-odigos-dev/sessions-resources/prod/Odigos_Joshua_tn.png',
    isPreview: true,
  },
];

export const PopUp: FC<PopUpInfoInput> = ({ show, index, onClose, memberships }) => {
  const MembershipEpicArray = ({ array }) => {
    return array.map((value, idx) => (
      <div className='flex items-center px-4 text-center rounded-full  bg-primary h-min' key={idx}>
        <p className='text-base text-white '>{value}</p>
      </div>
    ));
  };

  const aside = 'relative flex justify-center mx-auto mb-2';
  const popUp =
    'relative flex flex-col justify-center my-2 w-full sm:w-10/12 md:w-4/5 border-gray-200 border shadow-card rounded-3xl py-8 h-[80vh] overflow-y-auto bg-white';
  const container = 'absolute top-0 left-0 flex flex-col w-full px-8 py-8 gap-y-2';
  const sessionsText = 'mr-3 text-4xl text-primary font-futura-heavy';
  const vidContainer = 'flex justify-center flex-none';
  const videos = 'grid w-full grid-flow-col grid-rows-3 gap-2 h-min lg:grid-rows-1';

  const recursosContainer = 'flex flex-col flex-none mt-6 md:flex-row';
  const recText = 'mr-3 text-4xl text-primary font-futura-heavy';
  const recursos = 'flex flex-row items-center content-center flex-none gap-2';
  const resourcesCont = 'flex justify-center flex-none';
  const resourcesChild = 'grid w-full grid-flow-col grid-rows-2 gap-2 h-min md:grid-rows-1';

  const getMembershipP = 'flex justify-center flex-none w-full mb-4 text-5xl text-primary font-futura msm:text-4xl';

  return (
    <>
      <Modal.Modal crossDisplay={'absolute'} show={show} onClose={() => (onClose ? onClose() : {})} className='w-full sm:w-10/12 md:w-4/5'>
        <div className='self-center '>
          <aside className={aside}>
            <div className={popUp}>
              <div className={container}>
                <h1 className={sessionsText}>Sesiones</h1>
                <p className='text-lg text-primary font-futura-light'>
                  Acceso a publicación semanal de entrevistas con expertos. <br /> Descubre temas de tendencias del mercado inmobiliario,
                  interiorismo, arte y más{' '}
                </p>
                <div className={vidContainer}>
                  <div className={videos}>
                    {DataPopUpSessions.map((val, idx) => {
                      return (
                        <div key={idx} className='w-ful h-max'>
                          <MiniVideoCard
                            thumbnail={val.thumbnail}
                            title={val.title}
                            description={val.description}
                            duration={val.duration}
                            isPreview={val.isPreview}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className={recursosContainer}>
                  <h1 className={recText}>Recursos</h1>
                  <div className={recursos}>{index == 0 && <MembershipEpicArray array={['Membresía Studio', 'Membresía Growth']} />}</div>
                </div>
                <p className='text-lg text-primary font-futura-light'>
                  Acceso a publicación semanal de recursos educativos. <br />
                  Conviértete en un agente inmobiliario aplicando las mejores estrategias en tu negocio inmobiliario.
                </p>
                <div className={resourcesCont}>
                  <div className={resourcesChild}>
                    {DataResourcesCard.map((val, idx) => {
                      return (
                        <div key={idx} className='flex flex-initial w-full h-min'>
                          <ResourceCard title={val.title} description={val.description} img={val.img} />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className='flex flex-col flex-none mt-6 md:flex-row'>
                  <h1 className='mr-3 text-4xl text-primary font-futura-heavy'>Talleres</h1>
                  <div className='flex flex-row items-center content-center flex-none gap-2'>
                    {index == 0 && <MembershipEpicArray array={['Membresía Studio', 'Membresía Growth']} />}
                  </div>
                </div>
                <p className='text-lg text-primary font-futura-light'>
                  Capacítate junto con nuestro equipo de expertos. <br />
                  Acceso a talleres semanales sobre tendencias, aplicacion de estrategias y desarrollo tecnológico.
                </p>
                <div className='flex justify-center flex-none'>
                  <div className='grid w-full grid-flow-col grid-rows-1 gap-12 '>
                    {tallerImages.map((val, idx) => (
                      <div className='grow' key={idx}>
                        <img src={val} alt='image' className='w-full h-full' />
                      </div>
                    ))}
                  </div>
                </div>
                {index != 1 && (
                  <>
                    <div className='flex flex-col flex-none mt-6 md:flex-row'>
                      <h1 className='mr-3 text-4xl text-primary font-futura-heavy'>Asesorías Q&A</h1>
                      <div className='flex flex-row items-center content-center flex-none gap-2'>
                        {index == 0 && <MembershipEpicArray array={['Membresía Growth']} />}
                      </div>
                    </div>
                    <p className='text-lg text-primary font-futura-light'>
                      Ponemos a tu disposición un servicio de consultoría y asesoría inmobiliaria para llevar tus estrategias al siguiente nivel.{' '}
                      <br />
                      Recibe atención personalizada vía correo electrónico, visitas en tu oficina y videollamada.
                    </p>
                    <p className='w-full text-xl text-center text-primary font-futura'>
                      Marketing Digital • Publicidad • Legal y Fiscal • Neogiciaciones • Automatización
                    </p>
                  </>
                )}

                {index != 0 ? (
                  <div className='flex flex-row justify-center flex-wrap w-full mt-12'>
                    <p className='flex flex-none ml-4 text-primary font-futura'>Adquiere tu membresia hoy! </p>
                    <div className='flex flex-initial w-full ' />
                    {memberships && (
                      <MembershipDeal
                        title={memberships[index].type}
                        price={memberships[index].first_month_plan?.monthly_price}
                        renewPrice={memberships[index].monthly_plan?.monthly_price}
                      />
                    )}
                  </div>
                ) : (
                  <div className='flex flex-col w-full mt-12'>
                    <p className={getMembershipP}>Adquiere tu membresia hoy!</p>
                    <div className='flex flex-row w-full px-24 mt-5  mmd:grid mmd:grid-cols-1 msm:px-10 msm:'>
                      {memberships && (
                        <>
                          <MembershipDeal
                            title={memberships[1].type}
                            price={memberships[1].first_month_plan?.monthly_price}
                            renewPrice={memberships[1].monthly_plan?.monthly_price}
                            highlight={true}
                          />
                          <div className='flex flex-initial w-full ' />
                          <MembershipDeal
                            title={memberships[2].type}
                            price={memberships[2].first_month_plan?.monthly_price}
                            renewPrice={memberships[2].monthly_plan?.monthly_price}
                            highlight={true}
                          />
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </aside>
        </div>
      </Modal.Modal>
    </>
  );
};
