import { TechnicalSupportButton } from 'components/';
import { useRouter } from 'next/router';
import { pdfjs } from 'react-pdf';
import { RecoilRoot } from 'recoil';
import 'styles/app.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  return (
    <RecoilRoot>
      <Component {...pageProps} />
      <>{router.pathname != '/detalles/[codigo]' && <TechnicalSupportButton />}</>
    </RecoilRoot>
  );
}

export default MyApp;
