import React from 'react';

const UserIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11.11 13.23' className='inline w-4 h-4 mx-2 -mt-1.5 text-black '>
      <g>
        <g>
          <path fill='currentColor' d='M5.56,0A2.91,2.91,0,1,0,8.47,2.91,2.91,2.91,0,0,0,5.56,0Z' />
          <path
            fill='currentColor'
            d='M5.56,6.35A5.55,5.55,0,0,0,0,11.91V13a.26.26,0,0,0,.26.26H10.85a.26.26,0,0,0,.26-.26V11.91A5.55,5.55,0,0,0,5.56,6.35Z'
          />
        </g>
      </g>
    </svg>
  );
};

export default UserIcon;
