import { Button, Modal } from 'components/';
import { useState } from 'react';
import { WhatsappIcon } from '../../components/Svgs';
const TechnicalSupportButton = ({ show: showModalFromOut, onClose, onOpen }) => {
  const [ShowModal, setShowModal] = useState(false);
  const [textBug, setTextBug] = useState('');

  const tapButton = 'rounded-full h-16 w-16 flex items-center justify-center bg-primary border-2 border-white';
  const modalClass = 'w-full sm:w-10/12 md:w-4/5 xl:w-3/5';
  const formClass =
    'relative flex flex-col flex-wrap items-center justify-center flex-1 my-2 border shadow-lg border-secondary rounded-3xl h-[fit-content] py-8 md:sticky md:top-2 bg-white';

  return (
    <>
      <div className='fixed bottom-6 right-6 z-50 msm:bottom-10 msm:right-5'>
        <button onClick={() => (typeof onOpen === 'undefined' ? setShowModal(true) : onOpen())}>
          <div className={tapButton}>
            <img className='w-10' src='/SVG/help_outline_white.svg' alt='Ayuda' />
            {/* <p className="text-white">AYUDA</p> */}
          </div>
        </button>
      </div>
      <div>
        <Modal.Modal
          crossDisplay={'absolute'}
          show={typeof showModalFromOut === 'undefined' ? ShowModal : showModalFromOut}
          onClose={() => {
            typeof onClose === 'undefined' ? setShowModal(false) : onClose();
          }}
          className={modalClass}
        >
          <div className='self-center'>
            <aside className='relative flex justify-center w-full min-h-full mx-auto mb-2'>
              <form className={formClass}>
                <h1 className='justify-center text-2xl sm:text-4xl text-center'>¿Tienes algún problema?</h1>
                <p className='justify-center mx-10 text-center text-gray-800'>
                  ¡Podemos ayudarte!
                  <br />
                  Solo trata de ser lo más preciso posible a la hora de describir la problemática.
                </p>
                <textarea
                  className='w-4/5 h-40 pt-2 pl-2 m-2 text-sm border rounded-lg outline-none resize-none md:text-base no-scrollbar border-primary'
                  name='message'
                  placeholder='Describe con lujo de detalles las dificultades que has tenido aquí.'
                  onChange={(e) => setTextBug(e.target.value)}
                />
                <div className='flex flex-wrap md:flex-row-reverse gap-2 w-4/5 pt-2 m-2'>
                  <Button
                    variant='primary'
                    label={
                      <div className='inline-flex items-center place-items-center'>
                        <img className='w-4  md:w-6' src='/SVG/email_white.svg' />
                        <p className='pl-2'>Correo</p>
                      </div>
                    }
                    className='flex-1 md:flex-initial text-lg h-10 items-center'
                    onClick={() =>
                      window.open(
                        `mailto:alejandro.attolini@odigos.mx?subject=Reporte-Bug&body=` + encodeURI(`¡Hola!, he tenido una problemática: ${textBug}`)
                      )
                    }
                  />
                  <a href={`https://wa.me/5216561995762?text=` + encodeURI(`¡Hola!, he tenido una problemática: ${textBug}`)}>
                    <Button
                      variant='primary'
                      label={
                        <div className='inline-flex items-center place-items-center'>
                          <WhatsappIcon className='w-4  md:w-6 text-white' />
                          <p className='pl-2'>Whatsapp</p>
                        </div>
                      }
                      className='flex-1 md:flex-initial text-lg h-10 items-center'
                    />
                  </a>
                </div>
              </form>
            </aside>
          </div>
        </Modal.Modal>
      </div>
    </>
  );
};

export default TechnicalSupportButton;
