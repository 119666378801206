import React from 'react';
// import "./Spinner.css";
const Spinner = ({ variant = 'white' }) => {
  const color = {
    primary: 'primary',
    white: 'white',
    red: 'red',
    blue: 'blue',
  };
  return (
    <div className={['loading-spinner', color[variant] || 'white'].join(' ')}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Spinner;
