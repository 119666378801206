import { Button, Loading, Logo } from 'components/';
import useAuth from 'hooks/useAuth';
import useWindowSize from 'lib/hooks/useWindowSize';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { userState } from '../../atoms/UserAtom';
import HeaderSwitch from './headerSwitch';

interface InputHeader {
  variant: 'primary' | 'secondary' | 'transparent';
  shouldDisplayLogin?: boolean;
  shouldDisplayAdminMenu?: boolean;
  shouldDisplayGoToPortal?: boolean;
  onCreateAccount?: any;
}

const Header: FC<InputHeader> = ({
  variant = 'primary',
  shouldDisplayLogin = false,
  shouldDisplayAdminMenu = false,
  shouldDisplayGoToPortal = false,
  onCreateAccount,
  ...props
}) => {
  const windowSize = useWindowSize();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useRecoilState(userState);
  const [background, setBackground] = useState<string>();
  const [mobileBackground, setMobileBackground] = useState<string>();
  const [textColor, SetTextColor] = useState<string>();
  const router = useRouter();
  const { logout } = useAuth();
  useEffect(() => {
    setLoading(false);
  }, [router]);
  useEffect(() => {
    const headerStyles = HeaderSwitch(variant);
    setMobileBackground(headerStyles.MobileBackground);
    setBackground(headerStyles.background);
    SetTextColor(headerStyles.textColor);
  }, [variant]);
  const navItemClass = 'uppercase w-full md:w-auto flex h-full items-center';
  const mobileNavItemClass = 'uppercase w-4/5 block py-2 px-4 hover:bg-black hover:bg-opacity-40 border-b border-gray-400 rounded m-auto text-white';

  const optionBorder = (routerPath) => {
    return [
      windowSize.width > 768 ? navItemClass : mobileNavItemClass,
      textColor,
      router.pathname === routerPath ? 'border-b-2 border-primary' : '',
    ].join(' ');
  };
  const svgPath = [
    'M26.32,7.19C24.89,4.55,25.14,2.43,27,1a4.36,4.36,0,0,1,5.57.11,4.45,4.45,0,0,1,.55,6.09,1.09,1.09,0,0,0,.17.13c5.06,1.93,7.85,5.77,9,10.89a29.14,29.14,0,0,1,.44,5.29,40.15,40.15,0,0,0,2.69,13c.07.17.13.34.2.5.62,1.63.27,2.11-1.46,2.11-2.6,0-5.19,0-7.78,0a.7.7,0,0,0-.81.56,6.16,6.16,0,0,1-11.67,0A.79.79,0,0,0,23,39c-2.71,0-5.42,0-8.13,0-1.12,0-1.5-.56-1.17-1.62.77-2.47,1.61-4.92,2.24-7.43a36.17,36.17,0,0,0,.84-8.43,15.94,15.94,0,0,1,4.13-10.81,12.58,12.58,0,0,1,4.83-3.34C25.92,7.36,26.12,7.27,26.32,7.19ZM32,4.44a2.3,2.3,0,1,0-2.33,2.22A2.29,2.29,0,0,0,32,4.44h0ZM25.89,39.07a4.1,4.1,0,0,0,4,2.72c1.57-.06,3.45-1.47,3.61-2.72Z',
    'M59.47,25.19A17.26,17.26,0,0,1,54.76,36.9c-.63.65-1.23.76-1.71.31s-.41-1.05.21-1.71a15,15,0,0,0,.89-19.75c-.32-.41-.67-.79-1-1.17A1.06,1.06,0,0,1,53.06,13a1,1,0,0,1,1.53.16,16.78,16.78,0,0,1,4.68,9.42c0,.32.09.64.12.95C59.42,24,59.44,24.52,59.47,25.19Z',
    'M0,24.26a16.49,16.49,0,0,1,4.75-11,1.89,1.89,0,0,1,.67-.47.88.88,0,0,1,1.09.38,1,1,0,0,1-.11,1.32A27.77,27.77,0,0,0,4.46,16.9,15.09,15.09,0,0,0,6,35.33l.37.39a1.05,1.05,0,0,1,.07,1.5,1.08,1.08,0,0,1-1.51-.09,16,16,0,0,1-3.44-5.06A18.84,18.84,0,0,1,0,24.26Z',
    'M54.93,25.31a12.61,12.61,0,0,1-3.44,8.43,2,2,0,0,1-.56.44.92.92,0,0,1-1.14-.24,1,1,0,0,1-.1-1.22,2.74,2.74,0,0,1,.38-.45,10.51,10.51,0,0,0,0-14.33c-.06-.08-.14-.14-.2-.22a1.05,1.05,0,0,1,0-1.5,1,1,0,0,1,1.47.08,27.1,27.1,0,0,1,2.38,3.41A11.34,11.34,0,0,1,54.93,25.31Z',
    'M4.5,25A12.37,12.37,0,0,1,8,16.43c.54-.58,1.16-.65,1.62-.22a1.13,1.13,0,0,1-.16,1.64,10.47,10.47,0,0,0,0,14.5,1.14,1.14,0,0,1,.34,1.39.87.87,0,0,1-1,.5A1.85,1.85,0,0,1,8,33.78,12.59,12.59,0,0,1,4.5,25Z',
    'M12.16,19a4,4,0,0,1,.85.76,1,1,0,0,1-.24,1.2,5.92,5.92,0,0,0-.13,8.16,2,2,0,0,1,.43.63.93.93,0,0,1-.43,1.14,1,1,0,0,1-1.31-.2,7.53,7.53,0,0,1-2.13-4,7.74,7.74,0,0,1,2-7.06A5.78,5.78,0,0,1,12.16,19Z',
    'M50.4,25.32a8.08,8.08,0,0,1-2.13,5.23,1.1,1.1,0,0,1-1.62.26c-.47-.42-.42-1,.11-1.63a6,6,0,0,0,0-8.15c-.52-.58-.56-1.22-.1-1.63s1.09-.31,1.63.27A8.17,8.17,0,0,1,50.4,25.32Z',
  ];

  const regularLogout = async () => {
    await logout(setUser);
    router.replace('/');
  };

  const adminMenuOptions = [
    {
      key: 1,
      ref: '/admin/studio',
      title: 'Studio',
    },
    {
      key: 2,
      ref: '/admin/membresias',
      title: 'Membresías',
    },
    {
      key: 3,
      ref: '/admin/mi-perfil',
      title: 'Mi perfil',
    },
  ];
  const menuOptions = [
    {
      key: 1,
      ref: '/inmuebles',
      title: 'INMUEBLES',
    },
    {
      key: 2,
      ref: '/compara',
      title: 'COMPARA',
    },
    {
      key: 3,
      ref: '/wishlist',
      title: 'WISHLIST',
    },
  ];
  const CustomButton = ({ buttonPath, title }) => {
    return (
      <Link href={buttonPath}>
        <a onClick={() => setLoading(true)} className={optionBorder(buttonPath)}>
          {title}
        </a>
      </Link>
    );
  };
  const GettingOptions = ({ data }) => {
    return data.map((Options, key) => {
      return <CustomButton key={Options.key} buttonPath={Options.ref} title={Options.title} />;
    });
  };
  const toggleMenu = () => setIsMenuOpen((prev) => !prev);
  return (
    <header {...props}>
      {loading && <Loading />}
      <nav
        id='navHeader'
        className={[
          `md:grid w-full border-b border-white min-h-[56px] flex shadow `,
          background,
          shouldDisplayAdminMenu ? 'grid-cols-[0.5fr,1fr]' : 'grid-cols-2',
        ].join(' ')}
      >
        <Link href='/'>
          <a onClick={() => setLoading(true)}>
            <Logo className='my-2 ml-3 w-36' full={!(variant === 'secondary')} blanco={!(variant === 'secondary')} />
          </a>
        </Link>

        <button
          id='MenuButton'
          className={['relative flex w-10 p-2 ml-auto mr-0 focus:outline-none md:hidden hover hover:bg-opacity-50 hover:bg-black', textColor].join(
            ' '
          )}
          onClick={toggleMenu}
        >
          <span className='sr-only'>Open main menu</span>
          <div className={'absolute block w-5 transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2'}>
            <span
              aria-hidden='true'
              className={[
                'block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out',
                isMenuOpen ? 'rotate-45' : '-translate-y-1.5',
              ].join(' ')}
            ></span>
            <span
              aria-hidden='true'
              className={['block absolute  h-0.5 w-5 bg-current   transform transition duration-500 ease-in-out', isMenuOpen && 'opacity-0'].join(
                ' '
              )}
            ></span>
            <span
              aria-hidden='true'
              className={[
                'block absolute  h-0.5 w-5 bg-current transform  transition duration-500 ease-in-out',
                isMenuOpen ? '-rotate-45' : 'translate-y-1.5',
              ].join(' ')}
            ></span>
          </div>
        </button>
        <div
          id='desktopMenu'
          className={
            'hidden w-full h-auto text-xs tracking-widest top-14 md:flex-row md:items-center md:justify-around md:flex flex-nowrap font-futura-med'
          }
        >
          {shouldDisplayGoToPortal ? (
            <Link href={`/inmuebles`}>
              <a onClick={() => setLoading(true)} className={[navItemClass, textColor].join(' ')}>
                Ir al Portal
              </a>
            </Link>
          ) : (
            <>
              {shouldDisplayAdminMenu ? (
                <>
                  {user?.role === 'admin' && <CustomButton buttonPath={'/admin/inmuebles'} title={'Mis Inmuebles'} />}
                  {<GettingOptions data={adminMenuOptions} />}
                  {user?.role === 'admin' && (
                    <Link href={`/admin/inmuebles/publicar`}>
                      <a onClick={() => setLoading(true)}>
                        <Button primary label='Publicar Inmueble' />
                      </a>
                    </Link>
                  )}
                  <div className='relative  flex items-center float-left w-auto h-full'>
                    <svg viewBox='0 0 59.47 43.81' className='relative float-left w-10 h-auto' style={{ fill: '#085469' }}>
                      {svgPath.map((svg, key) => {
                        return <path key={key} d={svg} />;
                      })}
                    </svg>
                  </div>
                </>
              ) : (
                <>{<GettingOptions data={menuOptions} />}</>
              )}
            </>
          )}

          {shouldDisplayLogin && (
            <>
              <Button size='small' onClick={() => regularLogout()} label='Log out' />
            </>
          )}
        </div>
        <div
          id='MobileMenu'
          className={[
            'z-50 absolute w-full transition-all duration-300 h-auto text-xs tracking-widest  md:hidden top-14 font-futura-med ',
            mobileBackground,
            isMenuOpen ? 'opacity-1 visible' : 'opacity-0 invisible',
          ].join(' ')}
        >
          {shouldDisplayGoToPortal ? (
            <>
              <Link href={`/inmuebles`}>
                <a id='ir al portal ID' onClick={() => setLoading(true)} className={mobileNavItemClass}>
                  Ir al Portal
                </a>
              </Link>
            </>
          ) : (
            <>
              {shouldDisplayAdminMenu ? (
                <>
                  {user?.role === 'admin' && <CustomButton buttonPath={'/admin/inmuebles'} title={'Mis Inmuebles'} />}
                  {<GettingOptions data={adminMenuOptions} />}
                  <Link href={`/admin/inmuebles/publicar`}>
                    <a onClick={() => setLoading(true)}>
                      <Button className='ml-6' primary label='Publicar Inmueble' />
                    </a>
                  </Link>
                </>
              ) : (
                <>{<GettingOptions data={menuOptions} />}</>
              )}
            </>
          )}

          {shouldDisplayLogin && (
            <>
              <Button className='mt-3 ml-3' size='small' onClick={() => regularLogout()} label='Log out' />
            </>
          )}
        </div>
      </nav>
    </header>
  );
};
export default Header;
