import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const Teleport = ({ children, to }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);
  return mounted ? createPortal(children, document.querySelector(to || 'portal')) : null;
};
export default Teleport;
