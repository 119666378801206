import React from 'react';
// import "./Select.css";

const Select = React.forwardRef(({ name = 'titulo', id = 'id', children, className, ...rest }, ref) => {
  return (
    <select ref={ref} name={name} id={id} className={className} {...rest}>
      {children}
    </select>
  );
});
Select.Option = ({ value, label, ...rest }) => (
  <option value={value} {...rest}>
    {label}
  </option>
);

export default Select;
