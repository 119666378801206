import Api from 'api/Api';
import useAuth from 'hooks/useAuth';
import { Membership } from 'lib/types/membership';
import Link from 'next/link';
import React, { FC, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { MembershipCard } from '..';
import { userState } from '../../atoms/UserAtom';

interface informationInput {
  title: string;
  content: string;
  class: string;
  class2: string;
  contentClass: boolean;
  contentClass2?: boolean;
}
interface BannerInput {
  minimalMembership?: number;
}

export const Banner: FC<BannerInput> = ({ minimalMembership }) => {
  const { getToken } = useAuth();
  const [actualSubscription, setActualSubscription] = useState(undefined);
  const user = useRecoilValue(userState);
  const [bannerStates, setBannerStates] = useState<informationInput>();
  const [rendered, setRendered] = useState(false);
  const [memberships, setMemberships] = useState<Membership[]>();
  const getActualSubscription = async () => {
    try {
      const { data } = await Api(getToken()).get('memberships/actualSubscription');
      // console.log('Actual Subscription', data);
      if (data.id) {
        setActualSubscription(data);
      }
    } catch (e) {
      // console.log(e?.message);
    } finally {
      setRendered(true);
    }
  };
  const getAllMemberships = async () => {
    try {
      const { data } = await Api(getToken()).get<Membership[]>('memberships');
      data.sort((a, b) => a.index - b.index);
      data.map((value) => {
        const features: string[] = [];
        value.membership_features?.map((val) => features.push(val.feature));
        value.features = features;
      });
      setMemberships(data);
      // console.log(data);
    } catch (e) {
      // console.log(e);
    }
  };
  useEffect(() => {
    getActualSubscription();
    getAllMemberships();
  }, []);
  useEffect(() => {
    if (!user) setBannerStates(information[2]);
    else if ((user && !actualSubscription) || minimalMembership) {
      setBannerStates(information[0]);
    } else if (user && actualSubscription) {
      setBannerStates(information[1]);
    }
  }, [user, actualSubscription]);

  const information: informationInput[] = [
    {
      title: 'Adquiere una suscripción mensual para acceder a este contenido',
      content: 'usted se registró pero debe pagar',
      class: 'fixed',
      class2: 'p-8 overflow-y-hidden h-[70vh] bg-gradient-to-b from-[#80808000] via-[#757575a6] to-[#575757]',
      contentClass: false,
      contentClass2: true,
    },
    { title: 'title 2', content: 'usted puede ver el contenido', class: 'hidden', class2: 'p-8 inline-block', contentClass: false },
    {
      title: 'Suscríbete para acceder a este contenido exclusivo para miembros',
      content: 'Suscríbete para acceder a este contenido exclusivo para miembros',
      class: 'fixed',
      class2: 'p-8 overflow-y-hidden h-[70vh] bg-gradient-to-b from-[#80808000] via-[#757575a6] to-[#575757]',
      contentClass: true,
      contentClass2: false,
    },
  ];

  const containerPosition = `${rendered ? bannerStates?.class : ''} fixed mt-[16vh] mss:mt-[3.8em] mss:mb-[3.8em] z-40`;
  const bannerDisplay = `${bannerStates?.contentClass ? 'block' : 'hidden'} text-center bg-[#fff] p-3 w-[80%]
  mmd:w-[98%] sm:h-[24em] msm:h-[100%]  msm:w-[19em] h-[20em] lg:w-[35em] lg:h-[19em] ml-auto mr-auto rounded-2xl
   border-[#fff] border-2  text-xl `;
  const registerButton: any = [
    `bg-[rgba(203,47,58,1)] hover:bg-[rgba(214,62,75,1)] pr-1 text-[#fff]
   pb-2 pt-1 pl-1 rounded-lg mb-3 mt-3 msm:text-xl sm:text-2xl lg:text-3xl shadow-card`,
  ];

  return rendered ? (
    <div className='flex justify-center'>
      <div className={containerPosition}>
        <div className={bannerDisplay}>
          <b className='text-3xl text-primary text-center inline-block mb-3 mss:text-2xl'>{rendered ? bannerStates?.title : 'loading_'}</b>

          <div className={` w-[100%] `}>
            <p className='text-primary text-base mss:text-sm md:text-xl'>Impulsa tu crecimiento en bienes raíces y</p>
            <p className='text-primary text-base mss:text-sm md:text-xl'>aprende de los mejores profesionales inmobiliarios</p>

            <button className={registerButton}>
              <b>
                <Link href='https://odigos.mx/register'>
                  <a>Registro Gratuito</a>
                </Link>{' '}
              </b>{' '}
            </button>

            <p className='text-primary text-base mss:text-sm md:text-xl'>Forma parte de la mayor comunidad de bienes raíces</p>
            <p className='text-primary text-base mss:text-sm md:text-xl'>Ventas - Negociación - Tecnología - Marketing</p>

            <div className='h-[0.1em] bg-primary w-[90%] ml-auto mr-auto  mt-[1em] '></div>

            <div className='grid grid-cols-2 mmd:text-base ml-3 sm:mt-[2.7em] msm:mt-[1.2em] md:mt-5 mmd:mt-4 '>
              <div className=' sm:mr-auto mss:grid  rounded-2xl'>
                <p className='text-primary inline mss:inline-block'>Ya soy miembro, </p>

                <button className={`text-primary msm:inline-block`}>
                  <b>
                    <u className=''>
                      <Link href='https://odigos.mx/login'>
                        <a> Iniciar Sesión</a>
                      </Link>
                    </u>{' '}
                  </b>{' '}
                </button>
              </div>

              <div className='sm:ml-auto  mr-3 '>
                <p className='text-primary inline msm:inline-block'>Conocer más sobre </p>
                <button className={`text-primary`}>
                  <b>
                    <u>
                      <Link href='https://odigos.studio/'>
                        <a> odigos.studio</a>
                      </Link>
                    </u>{' '}
                  </b>{' '}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${bannerStates?.contentClass2 ? 'block' : 'hidden'} w-[100%] h-[75vh] sm:h-[79.3vh]
          mss:h-[100vh] z-40`}
        >
          <div
            className=' text-center bg-[#fff] p-3 w-[80%] mmd:w-[98%] sm:h-[100%] mss:h-[90%] mms:object-fill
               mss:overflow-scroll h-[100%] lg:w-[35em] lg:h-[24.8em] ml-auto mr-auto rounded-2xl border-[#fff] border-2 text-[#fff] text-xl mss:p-[0px] '
          >
            <b className='text-3xl text-primary msm:text-2xl text-center inline-block mb-3 mss:text-2xl'>
              {rendered ? bannerStates?.title : 'loading_'}
            </b>

            <div className=' flex justify-center mss:flex-wrap mss:content-center grid-cols-2 w-full text-primary msm:mt-3 gap-10 '>
              {memberships?.map((value, idx) => {
                if (value.index == 0) return;
                if (minimalMembership && value.index < minimalMembership) return;
                return (
                  <MembershipCard
                    key={idx}
                    title={value.type}
                    price={value.first_month_plan?.monthly_price}
                    renewPrice={value.monthly_plan?.monthly_price}
                    features={value.features}
                    highlight={value.type == 'STUDIO'}
                  />
                );
              })}
            </div>

            <div className='sm:ml-auto  mr-3 mt-6 msm:mt-4 mss:block mss:mb-2'>
              <button className={`text-primary border-solid border-primary border-b-[2px]`}>
                <Link href='/admin/membresias'>
                  <a> odigos.studio</a>
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
};
