import React from 'react';

const MiniCard = ({ last4, paymentCards }) => {
  const brand = paymentCards?.map((data) => data?.brand);
  return (
    <>
      <div className='h-[1.2em] w-full flex items-center float-left'>
        {brand == brand ? <img className='inline h-full ' src={`/images/${brand}.png`} /> : ''}
        <span className='text-sm text-primary pt-1'> ...{last4}</span>
        <p></p>
      </div>
    </>
  );
};

export default MiniCard;
