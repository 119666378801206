import { Agency } from 'lib/types/agency';
import { atom } from 'recoil';

interface UserInterface {
  id: string;
  email: string;
  name: string;
  role: string;
  agency: Agency;
  username: string;
  phone: string;
  is_admin: boolean | string;
  is_paid: boolean | string;
}

interface UserToken {
  id: string | number;
  role: string;
  user: UserInterface;
}

export const userState = atom({
  key: 'userDataState',
  default: {} as UserInterface,
});
