export const amenidades = {
  Generales: ['Amueblado', 'Estacionamiento Visitas', 'Vigilancia', 'Condominio', 'Pet Friendly', 'Zona Arbolada'],
  Espacios: [
    'Cocina Integral',
    'Desayunador',
    'Despensa',
    'Antecomedor',
    'Comedor',
    'Sala',
    'TV Room',
    'Biblioteca',
    'Bodega',
    'Cava',
    'Cuarto de Servicio',
    'Cuarto de Lavado',
    'Salón de Fiestas Propio',
    'Estudio',
  ],
  'Amenidades Exteriores': ['Alberca', 'Juegos Infantiles', 'Tenis', 'Padel', 'Futbol', 'Roof Garden', 'Jogging Track', 'Jardín', 'Sky Garden'],
  'Amenidades Interiores': [
    'Jacuzzi',
    'Alberca Techada',
    'Bar',
    'Boliche',
    'Business Center',
    'Gimnasio',
    'Cine',
    'Lounge',
    'Spa',
    'Salón de Fiestas',
    'Salón de Juegos',
    'Ludoteca',
    'Home Office',
    'Coworking Lounge',
  ],
};
