import Api from 'api/Api';
import { Accordion, Button, Label, Loading } from 'components/';
import { amenidades } from 'components/MultiStepForm/Steps/amenidades';
import useWindowSize from 'hooks/useWindowSize';
import { getQueryFromObject, prepopulateSearchBar, sortAlphabetically } from 'lib/utils';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { multiSelectOptions } from './propertys';

const SearchBar = ({ className, loggedIn }) => {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({ estados: [], colonias: [], alcaldias: [] });
  const { reset, getValues, register, setValue, watch } = useForm();
  const ref = useRef(null);
  const refMenu = useRef(null);
  const router = useRouter();
  const windowSize = useWindowSize();
  // selectWatchValue looks like
  // "selectedOption"
  // Or
  // { "selectedOption": true }
  const selectWatchValue = watch('status');
  const statusSelectValue = useMemo(() => {
    if (typeof selectWatchValue === 'string') return selectWatchValue;
    if (selectWatchValue) return Object.keys(selectWatchValue)[0];
    return '';
  }, [selectWatchValue]);
  useEffect(() => {
    // console.log('Antes: ', getValues());

    reset(
      {
        precio_min: 0,
        precio_max: 0,
      },
      {
        keepValues: false,
      }
    );

    // console.log('Despues: ', getValues());

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && !event.target.className.includes('rs__')) {
        setActive(false);
      }
    }
    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, router]);

  const handleLocationFilter = (filter, value, label) => {
    let parent,
      endPoint = '';
    switch (filter) {
      case 'estados':
        setValue(filter, label);
        setOptions((prev) => ({ ...prev, colonias: [], alcaldias: [] }));
        setValue('colonias', '');
        setValue('alcaldias', '');
        endPoint = 'alcaldias';
        parent = `?parent_id=${value}`;
        break;

      case 'alcaldias':
        setValue(filter, value.map((option) => option.label).join(','));
        parent = `?parent_id=${value.map((option) => option.value).join(',')}`;
        setOptions((prev) => ({ ...prev, colonias: [] }));
        setValue('colonias', '');
        endPoint = 'colonias';
        break;

      case 'colonias':
        setValue(filter, label);
        // setOptions((prev) => ({ ...prev, colonias: [] }));
        parent = `?parent_id=${value}`;
        break;

      default:
        break;
    }
    // console.log(filter, endPoint, parent);
    // console.log(watch());
    const fetchLocation = async (endPoint, parent, setOptions) => {
      const { data } = await Api().get(`location/${endPoint}${parent ? parent : ''}`);
      setOptions((prev) => ({ ...prev, [endPoint]: data || [] }));
    };
    endPoint && fetchLocation(endPoint, parent, setOptions);
  };
  useEffect(() => {
    prepopulateSearchBar(setValue, router.query, setOptions);
    setLoading(false);
  }, [router]);

  const menuOptions = [
    {
      title: 'Ubicación',
      body: (
        <div className='max-w-xs w-80'>
          <SearchBar.Filter
            value={watch('estados')}
            instanceId='estados'
            onChange={handleLocationFilter}
            options={options.estados}
            target='estados'
            placeholder='Estados'
          />
          <SearchBar.Filter
            value={watch('alcaldias')}
            instanceId='alcaldias'
            onChange={handleLocationFilter}
            options={options.alcaldias}
            isMulti
            target='alcaldias'
            placeholder='Alcaldias'
          />
          <SearchBar.Filter
            value={watch('colonias')}
            instanceId='colonias'
            onChange={handleLocationFilter}
            options={options.colonias}
            target='colonias'
            placeholder='Colonias'
          />
        </div>
      ),
    },
    {
      title: 'Operación',
      body: (
        <div className='flex flex-col py-2 pl-2 pr-6 min-w-max'>
          {multiSelectOptions.map(
            (option, key) =>
              option.filter === 'tipo_contrato' && (
                <label htmlFor={option.option} key={key}>
                  <input
                    {...register(option.filter + '.' + option.option)}
                    onChange={(e) => setValue(option.filter + '.' + option.option, e.target.checked)}
                    type='checkbox'
                    className='mr-2 capitalize rounded-full form-checkbox'
                    name={option.option}
                    id={option.option}
                  />
                  {option.option}
                </label>
              )
          )}
        </div>
      ),
    },
    {
      title: 'Precio',
      transform: windowSize.width > 640 ? 'translate(0px, 10px)' : 'translate(-35%, 10px)',
      body: (
        <div className='flex px-4 py-2 min-w-max tranform'>
          <span>$</span>
          <input
            type='text'
            {...register('precio_min')}
            id='precio_min'
            name='precio_min'
            className='px-2 mb-2 text-sm border-b outline-none border-primary w-36 font-futura-med focus:outline-none text-primary'
            placeholder='Desde'
            defaultValue={0}
            prefix='$'
          />
          <span className='ml-6'>$</span>
          <input
            type='text'
            {...register('precio_max')}
            id='precio_max'
            name='precio_max'
            className='px-2 mb-2 text-sm border-b outline-none border-primary w-36 font-futura-med focus:outline-none text-primary'
            placeholder='Hasta'
            defaultValue={0}
            prefix='$'
          />
        </div>
      ),
    },
    {
      title: 'Inmueble',
      body: (
        <Accordion
          title='Inmueble'
          content={
            <div className='flex flex-col min-w-max'>
              {multiSelectOptions.map(
                (option, key) =>
                  option.filter === 'tipo_propiedad' && (
                    <label htmlFor={option.option} key={key}>
                      <input
                        // {...register(option.filter)}
                        onChange={() => setValue(option.filter, option.option)}
                        type='checkbox'
                        className='mr-2 rounded-full form-checkbox'
                        name={option.option}
                        checked={Boolean(watch(option.filter) === option.option)}
                        id={option.option}
                      />
                      {option.option}
                    </label>
                  )
              )}
            </div>
          }
        />
      ),
    },
    {
      title: !windowSize.width ? 'Más Filtros' : windowSize.width > 640 ? 'Más Filtros' : '+ Filtros',
      transform:
        windowSize.width > 929
          ? 'translate(calc(80% * -1), 10px)'
          : windowSize.width > 640
            ? 'translate(calc(70% * -1), 10px)'
            : 'translate(calc(75% * -1), 10px)',
      body: (
        <div className='flex flex-col px-2 rounded min-w-max w-max md:flex-row'>
          <div className='flex flex-col items-center px-2 justify-evenly'>
            {loggedIn && (
              <>
                <div className='w-full'>
                  <Label to={'codigo'}>Código</Label>
                  <div className='flex'>
                    <input
                      name='codigo'
                      id='codigo'
                      type='text'
                      className='flex w-full px-2 border rounded-lg outline-none border-primary'
                      {...register('codigo')}
                      placeholder='ODO-DP-123456'
                      onChange={(e) => setValue('codigo', e.target.value)}
                    />
                  </div>
                </div>
                <div className='w-full'>
                  <Label to={'status'}>Estado</Label>
                  <div className='flex'>
                    <select
                      name='status'
                      id='status'
                      {...register('status')}
                      // onChange={(e) => setValue("status", e.target.value)}
                      // value={getValues("status")}
                      value={statusSelectValue}
                      className='flex w-full px-2 border rounded-lg border-primary focus:outline-none outline'
                    >
                      <option value=''>Todos</option>
                      <option value='activo'>Activo</option>
                      <option value='rechazado'>Rechazado</option>
                      <option value='borrador'>Borrador</option>
                      <option value='suspendido'>Suspendido</option>
                      <option value='revisión'>Revisión</option>
                    </select>
                  </div>
                </div>
              </>
            )}
            <div>
              <Label to={'recamaras'}>Recamaras</Label>
              <div className='flex gap-2 '>
                <Button
                  label='-'
                  size='small'
                  className='font-bold border outline-none focus:outline-none border-primary hover:bg-primary hover:text-white'
                  onClick={(e) => setValue('recamaras', Number(getValues('recamaras')) - 1 >= 0 ? Number(getValues('recamaras')) - 1 : 0)}
                />
                <input
                  name='recamaras'
                  type='number'
                  className='flex w-20 px-2 border rounded-lg border-primary'
                  defaultValue={0}
                  {...register('recamaras', { min: 0 })}
                  placeholder='0'
                  step='1'
                  min='0'
                  onChange={(e) => setValue('recamaras', e.target.value)}
                />
                <Button
                  label='+'
                  size='small'
                  className='font-bold border outline-none focus:outline-none border-primary hover:bg-primary hover:text-white'
                  onClick={() => setValue('recamaras', Number(getValues('recamaras')) + 1)}
                />
              </div>
            </div>
            <div>
              <Label to={'baños'}>Baños</Label>
              <div className='flex gap-2'>
                <Button
                  label='-'
                  size='small'
                  className='font-bold border outline-none focus:outline-none border-primary hover:bg-primary hover:text-white'
                  onClick={() => setValue('baños', Number(getValues('baños')) - 1 >= 0 ? Number(getValues('baños')) - 1 : 0)}
                />
                <input
                  name='baños'
                  type='number'
                  className='flex w-20 px-2 border rounded-lg border-primary'
                  defaultValue={0}
                  {...register('baños', { min: 0 })}
                  placeholder='0'
                  step='1'
                  min='0'
                  onChange={(e) => setValue('baños', e.target.value)}
                />
                <Button
                  label='+'
                  size='small'
                  className='font-bold border outline-none focus:outline-none border-primary hover:bg-primary hover:text-white'
                  onClick={() => setValue('baños', Number(getValues('baños')) + 1)}
                />
              </div>
            </div>
            <div>
              <Label to={'estacionamientos'}>Estacionamientos</Label>
              <div className='flex gap-2'>
                <Button
                  label='-'
                  size='small'
                  className='font-bold border outline-none focus:outline-none border-primary hover:bg-primary hover:text-white'
                  onClick={() =>
                    setValue('estacionamientos', Number(getValues('estacionamientos')) - 1 >= 0 ? Number(getValues('estacionamientos')) - 1 : 0)
                  }
                />
                <input
                  name='estacionamientos'
                  type='number'
                  className='flex w-20 px-2 border rounded-lg border-primary'
                  defaultValue={0}
                  {...register('estacionamientos', { min: 0 })}
                  placeholder='0'
                  step='1'
                  min='0'
                  onChange={(e) => setValue('estacionamientos', e.target.value)}
                />
                <Button
                  label='+'
                  size='small'
                  className='font-bold border outline-none focus:outline-none border-primary hover:bg-primary hover:text-white'
                  onClick={() => setValue('estacionamientos', Number(getValues('estacionamientos')) + 1)}
                />
              </div>
            </div>
          </div>
          <div className='flex flex-col min-w-max '>
            {Object.entries(amenidades).map(([title, items], key) => (
              <div className={'text-center my-4'} key={title + key}>
                <h3>{title}</h3>
                <div className='block w-4/5 bg-primary h-0.5 my-2 mx-auto'></div>
                <div className='grid justify-center w-4/5 grid-cols-2 m-auto text-left sm:grid-cols-3 gap'>
                  {items.map((x, key) => (
                    <div key={key} className='flex my-1 leading-4 '>
                      <div className='inline-flex'>
                        <label htmlFor={'amenidades.' + x.trim()} className='relative inline-block w-5 h-3 mx-auto my-1 leading-4'>
                          <input
                            // {...register("amenidades." + x.trim())}
                            checked={Boolean(watch('amenidades.' + x.trim())) || false}
                            value={Boolean(watch('amenidades.' + x.trim())) || false}
                            id={'amenidades.' + x.trim()}
                            name={'amenidades.' + x.trim()}
                            onChange={(e) => setValue('amenidades.' + x.trim(), Boolean(e.target.checked))}
                            type='checkbox'
                            className='w-0 h-0 opacity-0 checkbox-rounded'
                          />
                          <span className='absolute inset-0 transition-all duration-300 bg-gray-300 rounded-full cursor-pointer before:absolute before:h-2 before:w-2 before:left-[0.125rem] before:bottom-[0.125rem] before:rounded-full before:bg-white before:transition-all before:duration-300 checkbox-rounded-toggle' />
                        </label>
                      </div>
                      <label htmlFor={'amenidades.' + x.trim()} className='inline-flex cursor-pointer'>
                        <span className='mx-2 my-0 text-sm'>{x.trim()}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ),
    },
  ];

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const query = getQueryFromObject(watch());
    setLoading(true);
    router.push({
      pathname: router.pathname === '/' ? '/inmuebles' : router.pathname,
      query: query,
    });
  };

  return (
    <form onSubmit={handleFormSubmit} ref={ref} className={className}>
      {loading && <Loading />}
      {/* <div id="portalMenu" className="relative z-50 h-40 bg-black"></div> */}
      <div
        className={[
          'relative grid grid-cols-[1fr,1px,1fr,1px,1fr,1px,1fr,1px,1fr,1px,0.5fr] sm:mx-auto bg-white items-center text-center text-primary rounded-full justify-center  min-w-[70vw] border border-primary',
          active ? 'bg-[#f5f5f5fa]  text-opacity-80' : 'bg-white',
        ].join(' ')}
      >
        {menuOptions.map((option, key) => (
          <SearchBar.Option
            title={option.title}
            key={key}
            uid={key + 1}
            active={active}
            toggleActive={setActive}
            last={key === menuOptions.length}
            transform={option.transform}
          >
            {option.body}
          </SearchBar.Option>
        ))}
        {/* 
      <div className="w-[1px] h-1/2 bg-primary self-center"></div> */}
        <button
          aria-label='search'
          type='submit'
          className={['flex h-[104%] w-[106%] relative cursor-pointer hover:bg-primary hover:text-white rounded-full mr-[-1px] hover:-mx-1'].join(
            ' '
          )}
        >
          <div className='flex justify-center w-full py-3'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              viewBox='980.828 218.648 33 33'
              width='15pt'
              height='15pt'
              className='mx-auto '
            >
              <path
                d=' M 993.76 218.648 C 986.618 218.648 980.828 224.438 980.828 231.581 C 980.828 238.722 986.618 244.512 993.76 244.512 C 1000.902 244.512 1006.691 238.722 1006.691 231.581 C 1006.691 224.438 1000.902 218.648 993.76 218.648 Z  M 984.589 231.581 C 984.589 226.524 988.703 222.41 993.76 222.41 C 998.817 222.41 1002.93 226.524 1002.93 231.581 C 1002.93 236.636 998.817 240.751 993.76 240.751 C 988.703 240.751 984.589 236.636 984.589 231.581 Z  M 1004.242 242.062 C 1005.123 241.181 1006.552 241.181 1007.433 242.062 L 1013.638 248.266 C 1014.519 249.148 1014.519 250.577 1013.638 251.458 C 1013.197 251.898 1012.62 252.119 1012.042 252.119 C 1011.465 252.119 1010.887 251.898 1010.447 251.458 L 1004.242 245.253 C 1003.361 244.373 1003.361 242.944 1004.242 242.062 Z '
                fillRule='evenodd'
                fill='currentColor'
              />
            </svg>
          </div>
        </button>
      </div>
    </form>
  );
};

SearchBar.Option = ({ transform, uid, active, toggleActive, last, title, children }) => {
  return (
    <>
      <div
        className={[
          'text-sm px-1 sm:text-base cursor-pointer hover:bg-primary  rounded-full m-[-1px] hover:mx-[-4px] hover:text-white',
          uid === active && 'bg-primary text-white shadow-big mx-[-4px]',
        ].join(' ')}
      >
        <div onClick={() => toggleActive(uid === active ? null : uid)} className='py-3'>
          {title || 'titulo'}
        </div>
        <div
          className={[
            'absolute text-primary z-50 text-base bg-white list-none text-left rounded shadow-lg min-w-max w-max',
            uid === active ? 'block border-gray-200 border' : 'hidden',
          ].join(' ')}
          style={{
            transform: transform ? transform : 'translate(0px, 10px)',
          }}
        >
          {children}
        </div>
      </div>
      {!last && <div className='flex self-center min-w-[1px] h-6 bg-primary border-hidden'></div>}
    </>
  );
};

SearchBar.Filter = ({ options, target, placeholder, className, onChange, instanceId, isMulti, value }) => {
  const sorted = options
    ?.sort((option1, option2) => sortAlphabetically(option1.name, option2.name))
    ?.map((option) => ({ value: option.id, label: option.name }));
  return (
    <div className='relative flex flex-col search-filters'>
      <Select
        isDisabled={!sorted.length}
        instanceId={instanceId}
        options={sorted}
        isMulti={isMulti}
        className={'z-10 border-0 rs-container ' + className}
        placeholder={placeholder}
        value={sorted.filter((option) => value?.includes(option.label))}
        onChange={(value) => onChange(target, value.value ? value.value : value, value.label)}
        classNamePrefix='rs'
        menuPortalTarget={process.browser ? document.querySelector('#' + target) : null}
      ></Select>
      <div className='relative overflow-x-hidden overflow-y-scroll transition-all transform max-h-40 no-scrollbar'>
        <div id={target} className='box-border block w-full border-r-4 z-1'></div>
      </div>
    </div>
  );
};

export default SearchBar;
