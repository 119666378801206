import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';
import { SwiperSlide } from 'swiper/react';
import { PrincipalesAmenidades, Swiper } from '../../components';
import { UserIcon } from '../../components/Svgs';
import { Inmueble } from '../../lib/types/inmueble';
interface InputContainer {
  className?: string;
  children?: JSX.Element[];
}
interface InputBody {
  className?: string;
  children?: JSX.Element[];
}
interface InputCardFooter {
  className?: string;
  children?: JSX.Element;
}
interface CardInput {
  item: Inmueble;
  isAdmin: boolean;
  handleClick: () => void;
  direction: any;
}
const Card: FC<CardInput> = ({ item, isAdmin, handleClick, direction }) => {
  return (
    <Container>
      <div className='relative'>
        {isAdmin && <Status status={item?.status || 'borrador'} />}
        <Swipercomponent imagenes={item?.imagenes} direction={direction} handleClick={handleClick} />
      </div>
      <Link href={direction}>
        <a onClick={() => handleClick()}>
          {isAdmin && <UserInfo user={item.user} codigo_sq={item?.codigo_sq} />}
          <Title title={item?.titulo} />
          <Body>
            <p className='text-2xl font-bold'>
              {item?.tipo_contrato?.toUpperCase()} <strong>${Number(item?.costo).toLocaleString('en-US')}</strong> {item?.tipo_pago}
            </p>
            {isAdmin ? (
              <p className='p-0 mt-2 mb-1 text-xs whitespace-normal font-futura-med text-secondary'>
                {item?.tipo_propiedad} en {item.tipo_contrato} en {item?.ubicacion?.alcaldia},{item?.ubicacion?.colonia}
              </p>
            ) : (
              <></>
            )}
          </Body>
          <Footer>
            <PrincipalesAmenidades descripcion={item?.descripcion} />
          </Footer>
        </a>
      </Link>
    </Container>
  );
};
const Title = ({ title }) => <h1 className='text-clip overflow-hidden p-2 text-xl font-Champagne text-secondary h-16'>{title}</h1>;
const UserInfo = ({ user, codigo_sq }) => (
  <div className='flex items-center justify-between w-full px-2 mt-2'>
    <p className='text-xs text-gray-500'>{codigo_sq}</p>
    <p className='text-base text-black inline text-ellipsis overflow-hidden whitespace-nowrap'>
      <UserIcon />
      <strong className='self-center'>{user?.username}</strong>
    </p>
  </div>
);
const Body: FC<InputBody> = ({ className, children }) => (
  <div className={[className, 'text-secondary font-futura-med p-2 mt-2'].join(' ')}>{children}</div>
);
const Footer: FC<InputCardFooter> = ({ className, children }) => <div className={[className, 'p-2 mb-2'].join(' ')}>{children}</div>;
const Status = ({ status }) => {
  const colors = {
    borrador: 'bg-blue-600',
    activo: 'bg-green-600',
    suspendido: 'bg-gray-400',
    revision: 'bg-yellow-400',
    rechazado: 'bg-red-700',
  };
  return (
    <div className='absolute z-10 flex items-center gap-2 px-3 top-2 right-4'>
      <p className='px-3 text-white bg-black rounded-md bg-opacity-60'>{status}</p>
      <span className={[colors[status.toLowerCase()], 'border border-white w-[14px] h-[14px] rounded-full'].join(' ')}> </span>
    </div>
  );
};
const Swipercomponent = ({ imagenes, direction, handleClick }) => {
  return (
    <>
      <Swiper
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        navigation
        lazy
        className='text-white'
      >
        {imagenes?.map?.((imagen) => (
          <SwiperSlide className='overflow-y-hidden overflow-y-clip' key={imagen.id}>
            <Link href={direction}>
              <a>
                <div className='h-52 w-[95vw]  sm:w-80'>
                  <div className='object-cover w-full h-full bg-center'>
                    <Image src={imagen.url || '/images/404imagen.jpg'} alt={imagen.descripcion} layout='fill' />
                  </div>
                </div>
              </a>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};
const Container: FC<InputContainer> = ({ className, children }) => <div className={[className, 'tw-card-inmueble'].join(' ')}>{children}</div>;
export default Card;
