import { objectIsEmpty } from 'lib/utils';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const Pagination = ({ results }) => {
  const [pages, setPages] = useState([]);
  const router = useRouter();
  const range = (start, end) => {
    let length = end - start + 1;

    return Array.from({ length }, (_, idx) => idx + start);
  };
  const leftSiblingIndex = Math.max(results.currentPage - 2, 1);
  const rightSiblingIndex = Math.min(results.currentPage + 2, results.lastPage);
  useEffect(() => {
    setPages(range(leftSiblingIndex, rightSiblingIndex));
  }, [leftSiblingIndex, rightSiblingIndex]);
  const getPaginationText = () => {
    if (objectIsEmpty(router.query)) return 'México';

    return `${router.query.colonia ? router.query.colonia + ', ' : ''}${router.query.alcaldia ? router.query.alcaldia + ', ' : ''}${
      router.query.estado ? router.query.estado + ', ' : ''
    }México`;
  };
  const handleLink = (to) => {
    document.querySelector('body').scrollIntoView();
    if (!objectIsEmpty(router.query)) {
      let query = '';
      query = { ...router.query, page: to };
      return router.push({ query: query });
    }
    router.push({ query: { page: to } });
  };
  if (results.lastPage <= 1) return null;

  const PreviousButton =
    'relative inline-flex items-center self-center justify-center h-8 px-4 text-sm font-medium text-center text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50';
  const nextButton =
    'relative inline-flex items-center self-center justify-center h-8 px-4 ml-3 text-sm font-medium text-center text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50';

  return (
    <div className='flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6'>
      <div className='flex justify-between flex-1 sm:hidden'>
        {results.prevPage && (
          <button onClick={() => handleLink(`${results.prevPage}`)} className={PreviousButton}>
            Previous
          </button>
        )}
        {results.nextPage && (
          <button onClick={() => handleLink(`${results.nextPage}`)} className={nextButton}>
            Next
          </button>
        )}
      </div>
      <div className='hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'>
        <div>
          <p className='text-sm text-gray-700'>
            <span className='font-medium'> {results.currentPage * 12}</span> -<span className='font-medium'> {(results.currentPage + 1) * 12}</span>{' '}
            de más de
            <span className='font-medium'> {results.count} </span>
            casas en {getPaginationText()}
          </p>
        </div>
        <div>
          <nav className='relative z-0 inline-flex space-x-1' aria-label='Pagination'>
            {results.prevPage && (
              <button
                onClick={() => handleLink(`${results.prevPage}`)}
                className='relative inline-flex items-center px-2 py-2 text-sm font-medium bg-white text-black hover:bg-black hover:text-white !outline-none !focus:outline-none rounded-full'
              >
                <span className='sr-only'>Previous</span>
                <svg className='w-5 h-5' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                  <path
                    fillRule='evenodd'
                    d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z'
                    clipRule='evenodd'
                  />
                </svg>
              </button>
            )}
            {pages.map((page) => (
              <button
                key={'pagination-' + page}
                onClick={() => handleLink(`${page}`)}
                className={[
                  '!outline-none !focus:outline-none transition-colors duration-150 rounded-full mx-2',
                  page === results.currentPage
                    ? 'z-10 bg-black border-primary text-white relative inline-flex items-center w-8 h-8 justify-center  self-center text-center text-sm font-medium'
                    : 'bg-white text-black hover:bg-black hover:text-white hidden md:inline-flex relative items-center w-8 h-8 justify-center  self-center text-center text-sm font-medium',
                ].join(' ')}
              >
                {page}
              </button>
            ))}
            {results.nextPage && results.lastPage && results.lastPage - 3 === results.currentPage && (
              <>
                <span className='relative inline-flex items-center self-center justify-center w-8 h-8 text-sm font-medium text-center text-black'>
                  ...
                </span>
                <button
                  onClick={() => handleLink(`${results.lastPage}`)}
                  className='relative inline-flex items-center px-2 py-2 text-sm font-medium bg-white text-black hover:bg-black hover:text-white !outline-none !focus:outline-none rounded-full'
                >
                  {results.lastPage}
                </button>
              </>
            )}
            {results.nextPage && (
              <button
                onClick={() => handleLink(`${results.nextPage}`)}
                className='relative inline-flex items-center px-2 py-2 text-sm font-medium bg-white text-black hover:bg-black hover:text-white !outline-none !focus:outline-none rounded-full'
              >
                <span className='sr-only'>Next</span>
                <svg className='w-5 h-5' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                  <path
                    fillRule='evenodd'
                    d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                    clipRule='evenodd'
                  />
                </svg>
              </button>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
