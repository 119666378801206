import Api from 'api/Api';
import { ConektaIframe, MembershipCard, Modal, Toast } from 'components/';
import useAuth from 'lib/hooks/useAuth';
import { Customer } from 'lib/types/customer';
import { PaymentCard } from 'lib/types/customer_card';
import { Membership } from 'lib/types/membership';
import Script from 'next/script';
import React, { FC, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { userState } from '../../atoms/UserAtom';

interface PopUpInput {
  show: boolean;
  index: number;
  onClose?: () => void;
}

interface PopUpBuyInput extends PopUpInput {
  membership: Membership | undefined;
  onFinishProcess: () => void;
}

export const PopUpBuyMembership: FC<PopUpBuyInput> = ({ show, onClose, index = 0, membership, onFinishProcess }) => {
  // const [paymentCards, setPaymentCards] = useState<PaymentCard[]>();
  const [selectedCard, setSelectedCard] = useState<PaymentCard | undefined>(undefined);
  const [customer, setCustomer] = useState<Customer>();
  const [buySuccess, setBuySuccess] = useState(false);
  const [checkoutId, setCheckoutId] = useState(undefined);
  const [isBusy, setIsBusy] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  //---
  const [cardId, setCardId] = useState<number | string>(0);
  const [indeex, setIndeex] = useState(0);
  const [daata, setDaata] = useState();

  // const [createCard, setCreateCard] = useState(false);
  const [user, setUser] = useRecoilState(userState);
  const klaviyoBuySuscription = () => {
    try {
      if (_learnq) {
        _learnq.push([
          'identify',
          {
            $email: user.email,
          },
        ]);
        const track = [
          'track',
          `Compra suscripción ${membership?.type}`,
          {
            $email: user.email,
            $first_name: user.name,
          },
        ];

        _learnq.push(track);
      }
    } catch (e) {}
    // console.log('buySuccess');
    // console.log(membership?.type, 'bought');
  };

  const { getToken } = useAuth();
  // const getCards = async () => {
  //   try {
  //     const { data } = await Api(getToken()).get<PaymentCard[]>('memberships/customerCards');
  //     setPaymentCards(data);
  //   } catch (e) {
  //     // setErrorMessage(e?.message || 'Un error inesperado ha ocurrido');
  //   } finally {
  //     // setIsLoading(false);
  //   }
  // };
  const getCustomer = async () => {
    try {
      const { data } = await Api(getToken()).get<Customer>('memberships/customer');
      // console.log('hay customer C:', data);
      setCustomer(data);
    } catch (e) {
      // console.log(e);
      // setErrorMessage(e?.message || 'Un error inesperado ha ocurrido');
    }
  };
  const createCheckoutToken = async () => {
    // setLoading(true);
    try {
      const { data } = await Api(getToken()).post('memberships/createCheckoutToken');
      setCheckoutId(data.checkoutId);
      // console.log(data);
    } catch (e) {
      // setErrorMessage(e?.message || 'Un error inesperado ha ocurrido');
    } finally {
      // setLoading(false);
    }
  };
  const handleCardTokenized = async (tokenizedData) => {
    setIsBusy(true);
    try {
      const { data } = await Api(getToken()).post<PaymentCard>(`memberships/${customer ? 'setDefinitiveCard' : 'createCustomerWithToken'}`, {
        checkoutId: tokenizedData.id,
      });
      // console.log(data);
      setSelectedCard(data);
      // if (customer) {
      //   await Api(getToken()).post(`memberships/updateDefaultCard/${data.id}`);
      // }
      setCheckoutId(undefined);

      await suscribeMembership(data);
      // if (data) setSuccessMessage('Datos procesados');
    } catch (e) {
      setToastMessage(e.response.data.message + ' Revisa los datos o intenta con otro método de pago');
      // setErrorMessage(e?.message || 'Un error inesperado ha ocurrido');
    } finally {
      // setCheckoutId(undefined);
      // setPopUpAddChange(false);
      setIsBusy(false);
    }
    // console.log(data);
  };
  const suscribeMembership = async (card: PaymentCard) => {
    if (card && index != 0) {
      const { data } = await Api(getToken()).post(`memberships/suscribeToMembership/${index}/${card.id}`);
      setBuySuccess(true);
      setCardId(card.id);
      setIndeex(index);
      setDaata(data);
    }
  };
  const loadAll = async () => {
    // await getCards();
    await getCustomer();
    await createCheckoutToken();
  };
  useEffect(() => {
    loadAll();
  }, []);

  const asideHeight = buySuccess ? 'msm:h-[37em]' : ' h-[30em] mss:h-[60em] ';
  const aside = 'relative flex justify-center w-full h-full mss:mt-[2em] md: mt-[2em]';
  const container = `block text-center bg-[#fff] w-[50.5em] mlg:w-full msm:w-[90em] ${asideHeight} 
  ml-auto mr-auto rounded-lg shadow-card text-xl `;
  const header = 'flex items-center justify-center h-[2.3em] bg-primary text-lg rounded-tl-lg rounded-tr-lg ';
  const popUpName = 'text-[#fff] ml-auto mr-auto';
  const buyProcess = 'float-right mb-3 mr-2 transition-all transform';
  const closeButton = ' text-3xl text-[#fff] transform  rounded-full cursor-pointer border-primary hover:border-white hover:text-gray-300';

  const gridContainer = 'grid grid-cols-2 ml-5 msm:grid-cols-1 mmd:ml-0';
  const finishProcessButton = 'bg-primary text-[#fff] rounded-xl w-[18em] text-base py-[0.1em] px-0 shadow-card mr-auto ml-auto mt-6 msm:w-full';

  return (
    <>
      <Modal.Modal crossDisplay={'hidden'} show={show} onClose={() => (onClose ? onClose() : {})} className=' w-full h-full'>
        <div className='self-center w-full'>
          <aside className={aside}>
            <div className={container}>
              {toastMessage && <Toast variant='error' msg={toastMessage} onHide={() => setToastMessage('')} />}
              <div className={header}>
                <span className={popUpName}>Plan de suscripción Mensual</span>
                <div
                  className={buyProcess}
                  onClick={() => {
                    if (!isBusy && onClose && !buySuccess) onClose();
                    else if (!isBusy && buySuccess) onFinishProcess();
                  }}
                >
                  <div className=''></div>
                  <p className={closeButton}>x</p>
                </div>
              </div>

              <div className={gridContainer}>
                <div>
                  {buySuccess ? (
                    <div className='mt-9'>
                      {klaviyoBuySuscription()}
                      <img className='inline w-[5em]' src='/images/checkmark.png' />
                      <div className='text-primary text-sm text-left font-semibold w-[18em] ml-auto mr-auto mt-2'>
                        <p className='float-left'>Tu pago se procesó correctamente.</p>
                        <p className='float-left'>Enviamos la confirmación de tu compra al correo electrónico registrado.</p>
                      </div>
                      <button className={finishProcessButton} onClick={() => onFinishProcess()}>
                        finalizar
                      </button>
                    </div>
                  ) : (
                    <div className='flex flex-1 sm: h-[38rem] mss:h-[48rem] '>
                      {/* <PaymentMethod /> */}
                      {
                        /*paymentCards?.length == 0 || createCard */ true ? (
                          checkoutId && (
                            <ConektaIframe
                              checkoutId={checkoutId}
                              onCardTokenized={handleCardTokenized}
                              onError={(error) => {
                                // setErrorMessage(error.message_to_purchaser);
                              }}
                            />
                          )
                        ) : (
                          <div></div>
                        )
                      }
                    </div>
                  )}
                </div>

                <div className='flex flex-col msm:mt-2 mt-10 items-center '>
                  <div className='block  align-center '>
                    {membership && (
                      <MembershipCard
                        title={membership.type}
                        price={membership.first_month_plan?.monthly_price}
                        renewPrice={membership.monthly_plan?.monthly_price}
                        features={membership.features}
                        highlight={membership.type == 'STUDIO'}
                      />
                    )}
                  </div>

                  <div className='block w-[93%] '>
                    <p className=' block text-sm text-center w-full text-[grey]'>
                      Cancela antes del{' '}
                      {new Date(Date.now() + 1000 * 60 * 60 * 24 * 14).toLocaleDateString('es-ES', { day: 'numeric', month: 'long' })} para obtener un
                      reembolso completo y evitar un cargo. Puede cancelar tu suscripción en cualquier momento a través del perfil de tu cuenta de
                      Odigós o contactando con el Servicio de atención al cliente. En promociones y descuentos no hay reembolsos
                    </p>
                  </div>

                  {/* <button
                      className={[
                        ' text-[#fff] rounded-xl w-[17em] text-base py-[0.1em] px-0 shadow-card mr-auto ml-auto mt-6 msm:w-full ',
                        selectedCard ? ' bg-primary ' : ' bg-gray-500 pointer-events-none ',
                      ].join(' ')}
                      disabled={!selectedCard}
                      onClick={(e) => suscribeMembership()}
                    >
                      finalizar
                    </button> */}
                </div>
              </div>
            </div>
            <Script
              src='https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Rruh78'
              onLoad={() => {
                // klaviyoArticle();
              }}
            />
          </aside>
        </div>
      </Modal.Modal>
    </>
  );
};
