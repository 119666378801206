import Api from 'api/Api';
import useAuth from 'hooks/useAuth';
import React, { useState } from 'react';

const PopUpUpdatePassword = ({ show, onClose, updateToast }) => {
  const { getToken } = useAuth();
  const [actualPass, setActualPass] = useState('');
  const [newPass1, setNewPass1] = useState('');
  const [newPass2, setNewPass2] = useState('');

  const updatePassword = async () => {
    try {
      const send = {
        actualPassword: actualPass,
        password: newPass1,
        confirmation_password: newPass2,
      };
      const { data } = await Api(getToken()).post('users/update-password', send);
      if (data.message) {
        updateToast(data.message, 'success');
        onClose?.();
      }
    } catch (e) {
      updateToast(e?.response.data.message, 'error');
    }
  };

  const popUp = 'absolute mt-[20vh] w-[100%] h-[70vh]  z-40';
  const container =
    'block text-center bg-[#fff]  w-[32em] msm:w-[18em] h-[15.5em] ml-auto mr-auto rounded-lg shadow-card border-[#80a8b383]  border-[1px] text-xl';
  const popUpHeader = 'flex items-center justify-center h-[2.3em] bg-primary text-lg rounded-tl-lg rounded-tr-lg';
  const PopHeaderSpan = 'text-[#fff] ml-auto mr-auto';
  const closeButton = ' mr-[0.5em] text-[#fff]';
  const inputsContainer = 'text-primary w-[13em] ml-auto mr-auto';
  const inputType = 'text-base float-left mt-[0.9em]';
  const inputStyle = 'border-[#17528a] border-[1px] rounded-lg w-full';
  const saveButton = 'bg-primary text-[#fff] rounded-xl text-base py-1 px-6';

  return show ? (
    <>
      <div className={popUp}>
        <div className={container}>
          <div className={popUpHeader}>
            <span className={PopHeaderSpan}>Actualizar Contraseña</span>
            <button className={closeButton} onClick={onClose}>
              X
            </button>
          </div>
          <div className={inputsContainer}>
            <b className={inputType}>Contraseña Actual</b>
            <input type='password' name='actualPassword' onChange={(e) => setActualPass(e.target.value)} className={inputStyle} />
            <b className={inputType}>Nueva Contraseña</b>
            <input type='password' name='newPassword1' onChange={(e) => setNewPass1(e.target.value)} className={inputStyle} />
            <b className={inputType}>Confirma Nueva Contraseña</b>
            <input type='password' name='newPassword2' onChange={(e) => setNewPass2(e.target.value)} className={inputStyle} />
          </div>

          <div className='relative float-left w-full h-auto mt-[1em] '>
            <button className={saveButton} onClick={() => updatePassword()}>
              Guardar Contraseña
            </button>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div />
  );
};

export default PopUpUpdatePassword;
