import React, { FC } from 'react';

interface ServiceInput {
  title: string;
  img: string;
  description: string;
  price: number;
  bgColor: string;
  onMoreInfoClick?: () => void;
}

export const ServiceCard: FC<ServiceInput> = ({ title, img, description, price, bgColor, onMoreInfoClick }) => {
  const serviceCard =
    'flex flex-none relative w-64 mss:w-[14.5em] h-[420px] mss:h-[22.7em] border-gray-200 border shadow-card rounded-xl transform motion-safe:hover:scale-105 transition-all duration-200';
  const moreInfoButton = 'flex flex-none text-lg uppercase border-b cursor-pointer text-primary font-futura border-primary';
  const titleContainer = 'flex justify-center flex-none w-full px-2 py-2 border-b border-primary';
  const titlep = 'text-2xl font-thin uppercase font-futura-light text-primary';
  const descriptionContainer = 'flex items-center justify-center flex-none w-full px-6 msm:px-4 mss:px-0';
  const textDescription = 'text-lg text-center  msm:text-base text-primary font-futura-cond mss:text-sm';
  return (
    <>
      <div className={serviceCard}>
        <div className='flex flex-col w-full'>
          <div className='flex flex-none w-full px-4'>
            <div className={titleContainer}>
              <p className={titlep}>{title}</p>
            </div>
          </div>
          <div className='flex justify-center flex-none w-full px-4 py-6 mss:py-4 '>
            <div className={`w-full border  h-36 border-primary`} style={{ backgroundColor: bgColor }}></div>
          </div>
          <div className={descriptionContainer}>
            <p className={textDescription}>
              <b>{description}</b>
            </p>
          </div>
          <div className='absolute flex flex-none w-full px-4 pt-2 bottom-7'>
            <div className='flex flex-initial w-full' />
            <p className={moreInfoButton} onClick={() => (onMoreInfoClick ? onMoreInfoClick() : {})}>
              Más info
            </p>
          </div>
          {/* <div className='absolute bottom-0 flex flex-row items-center justify-center w-full bg-primary rounded-b-xl'>
              <p className='flex flex-none py-2 text-2xl text-white msm:text-lg font-futurauppercase'>Desde ${price} MXN</p>
            </div> */}
        </div>
      </div>
    </>
  );
};
