import { FC, ReactNode, useEffect, useRef, useState } from 'react';
interface AccordionProps {
  title: string;
  disabled?: boolean;
  content: ReactNode;
}
const Accordion: FC<AccordionProps> = ({ title, disabled, content, ...props }) => {
  const [active, setActive] = useState(false);
  const contentSpace = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current?.contains(event.target)) {
        setActive(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref]);

  const accButton: string[] = [
    'box-border flex items-center justify-between gap-4 px-2 appearance-none cursor-pointer focus:outline-none w-full transform duration-700 ease',
    active ? 'border-b border-primary' : '',
    disabled ? 'text-gray-500' : '',
  ];
  const svgClass: any = ['transform duration-700 ease', active && 'rotate-180'];
  const contenido = 'px-4 mt-2 overflow-auto duration-700 ease-in-out transition-max-height scrollbar';
  const contentStyle: any = {
    maxHeight: `${active ? `${contentSpace?.current?.scrollHeight || 0}px` : '0px'} `,
  };

  return (
    <div id='Accordion' className='flex flex-col' ref={ref} {...props}>
      <button type='button' className={accButton.join(' ')} onClick={() => setActive((prev) => !prev)} disabled={disabled}>
        <div className=' truncate'>
          <p className='py-2  font-futura-med light'>{title}</p>
        </div>
        <div>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            viewBox='464.123 303.437 16.85 9.729'
            width='15pt'
            height='8pt'
            className={svgClass}
          >
            <path
              d=' M 472.585 313.165 C 472.715 313.156 472.84 313.095 472.939 312.991 L 480.763 304.663 C 481.012 304.417 481.044 303.978 480.835 303.686 C 480.628 303.392 480.258 303.353 480.008 303.6 C 480 303.609 479.989 303.619 479.981 303.629 L 472.548 311.538 L 465.114 303.629 C 464.881 303.364 464.508 303.375 464.286 303.652 C 464.061 303.93 464.07 304.368 464.305 304.634 C 464.314 304.644 464.323 304.653 464.333 304.663 L 472.157 312.991 C 472.274 313.114 472.429 313.177 472.585 313.165 Z '
              fill='currentColor'
            />
          </svg>
        </div>
      </button>
      <div ref={contentSpace} style={contentStyle} className={contenido}>
        <div className='pb-2'>{content}</div>
      </div>
    </div>
  );
};

export default Accordion;
