import React from 'react';

function Label({ to = '', className = '', children }) {
  return (
    <label htmlFor={to} className={className}>
      {children}
    </label>
  );
}

export default Label;
