import React from 'react';
// // Import Swiper styles
// import "./styles/swiper.css";
// import "./styles/swiper-pagination.css";
// import "./styles/swiper-navigation.css";
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
// Import Swiper React components
import { Swiper as DefSwiper } from 'swiper/react';

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

export default function Swiper({ cssMode = true, grabCursor = true, children, ...props }) {
  return (
    <DefSwiper cssMode={cssMode} grabCursor={grabCursor} {...props}>
      {children}
    </DefSwiper>
  );
}
