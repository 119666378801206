import React, { FC, useEffect, useState } from 'react';
interface ToastProps {
  variant?: string | 'success' | 'error' | 'info' | 'warning';
  msg?: string | string[];
  timeout?: number;
  onHide?: () => void;
}
const Toast: FC<ToastProps> = ({ variant = 'success', msg = 'message', timeout = 1500, onHide }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const time = setTimeout(() => {
      setShow(false);
      setTimeout(() => onHide?.(), 250);
    }, timeout);
    return () => {
      clearTimeout(time);
    };
  }, [timeout]);
  const styles = {
    success: ['bg-green-500 border-l-4 border-green-700', 'text-green-500'],
    warning: ['bg-orange-500 border-l-4 border-orange-700', 'text-orange-500'],
    error: ['bg-red-500 border-l-4 border-red-700', 'text-red-500'],
    info: ['bg-blue-500 border-l-4 border-blue-700', 'text-blue-500'],
  };
  if (!['success', 'error', 'info', 'warning'].includes(variant)) return null;

  return (
    <div
      className={[
        'flex items-center border-l-4  py-2 px-3 shadow-md mb-2 transition-all duration-200',
        styles[variant]?.[0],
        show ? 'visible opacity-100' : 'invisible opacity-0',
      ].join(' ')}
    >
      <div className={['rounded-full bg-white mr-3', styles[variant][1]].join(' ')}>
        <svg width='1.8em' height='1.8em' viewBox='0 0 16 16' className='bi bi-check' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            d='M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z'
          />
        </svg>
      </div>
      <div className='max-w-xl text-white '>
        {typeof msg === 'string' ? (
          <p>{msg}</p>
        ) : (
          msg.map((todo, index) => (
            <p key={index}>
              {todo}
              <br />
            </p>
          ))
        )}
      </div>
    </div>
  );
};

export default Toast;
