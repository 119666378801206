import { VariantNames } from './headerVariant';
interface headerStyles {
  background: string;
  textColor: string;
  MobileBackground: string;
}

const HeaderSwitch = (variant: string) => {
  const styles: headerStyles = {
    background: 'relative bg-primary',
    textColor: 'text-white',
    MobileBackground: 'relative bg-primary',
  };
  switch (variant) {
    case VariantNames.primary:
      styles.background = 'relative bg-primary';
      styles.textColor = 'text-white';
      styles.MobileBackground = 'bg-primary';
      break;
    case VariantNames.secondary:
      styles.background = 'relative bg-white';
      styles.textColor = 'text-primary';
      styles.MobileBackground = 'bg-white';

      break;
    case VariantNames.transparent:
      styles.background = 'z-[3] absolute top-0 bg-black bg-opacity-40';
      styles.textColor = 'text-white';
      styles.MobileBackground = 'bg-black bg-opacity-40';
      break;
  }
  return styles;
};
export default HeaderSwitch;
