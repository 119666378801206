import Api from 'api/Api';
import { userState } from 'atoms/UserAtom';
import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
// Hook
function useAuth() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [user, setUser] = useRecoilState(userState);
  const [error, setError] = useState(null);

  const createLocalStorageItem = async (key, value) => {
    localStorage.setItem(key, value);
    return true;
  };

  const getUserInfo = async () => {
    try {
      if (getToken()) {
        const res = await Api(getToken()).get(`users/me`);
        if (res.status === 200) {
          // console.log(res.data);
          setUser(res.data);
          return res.data;
        }
      }
      return null;
    } catch (error) {
      // console.log(error);
      return null;
    }
  };
  const getToken = () => {
    const token = JSON.parse(localStorage.getItem('logInfo'))?.access_token;
    const decoded = token ? jwt_decode(token) : null;
    if (decoded?.exp * 1000 > new Date().getTime()) {
      return token;
    }
    return null;
  };

  const login = async (credentials = {}) => {
    localStorage.removeItem('logInfo');

    if (!credentials)
      throw new Error(
        JSON.stringify({
          campos: ['username', 'password'],
          message: 'Llena todos los campos',
        })
      );

    if (!credentials.username)
      throw new Error(
        JSON.stringify({
          campos: ['username'],
          message: 'Porfavor introduce un nombre de usuario',
        })
      );

    if (!credentials.password)
      throw new Error(
        JSON.stringify({
          campos: ['password'],
          message: 'Porfavor introduce una contraseña',
        })
      );
    // console.log({ credentials });

    const { data } = await Api().post('auth/login', credentials);
    // console.log({ data });

    createLocalStorageItem('logInfo', JSON.stringify({ ...data }));
    const decoded = jwt_decode(data.access_token);
    // console.log('DECODED -> ', decoded.user);
    if (data && decoded) {
      setUser(decoded.user);
    }
    return {
      success: true,
      data: decoded.user,
    };
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const logout = async (cb) => {
    localStorage.removeItem('logInfo');
    setUser(null);
    cb(null);
    return true;
  };

  return { user, error, login, logout, getUserInfo, getToken };
}

export default useAuth;
