import React, { useState } from 'react';
import Link from 'next/link';
import { PropTypes } from 'prop-types';
import { SwiperSlide } from 'swiper/react';
import { Swiper, Loading } from 'components/';
import classes from './FrequentLinkCard.module.css';
import Image from 'next/image';

const FrequentLinkCard = ({ href, image, title }) => {
  return (
    <Link href={href}>
      <div className={['mx-auto my-5 rounded-[30px]', classes.link_card].join(' ')}>
        {/* <img src={image} alt="title" /> */}
        <div className='z-0'>
          <Image src={image} alt={title} width='240' height='280' layout='responsive' />
        </div>
        <div className={[classes.body, 'z-10'].join(' ')}>
          <div className={classes.title}>{title}</div>
          <div className={classes.divider}></div>
        </div>
      </div>
    </Link>
  );
};
FrequentLinkCard.propTypes = {
  /**
   * Which url should be targeted.
   */
  to: PropTypes.string,
  /**
   * What iamge should be displayed.
   */
  image: PropTypes.string,
  /**
   * Title of the link card.
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

FrequentLinkCard.defaultProps = {
  to: '',
  image: '/images/404imagen.jpg',
  title: 'Link frequente',
};

FrequentLinkCard.List = ({ items }) => {
  const [loading, setLoading] = useState(false);
  return (
    <>
      {loading && <Loading />}
      <Swiper
        cssMode={false}
        grabCursor={true}
        slidesPerView={1}
        spaceBetween={30}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
          1280: {
            slidesPerView: 4,
          },
          1536: {
            slidesPerView: 5,
          },
        }}
        navigation
      >
        {items?.map((item, index) => (
          <SwiperSlide key={index}>
            <div onClick={() => setLoading(true)}>
              <FrequentLinkCard href={item.to} image={item.image} title={item.title} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

FrequentLinkCard.List.propTypes = {
  /**
   * Array of items instaces of FrequentLinkCard
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      image: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

FrequentLinkCard.List.defaultProps = {
  items: [
    { to: '#', image: '/images/404-image.png', title: 'link de prueba' },
    { to: '#', image: '/images/404-image.png', title: 'link de prueba' },
    { to: '#', image: '/images/404-image.png', title: 'link de prueba' },
    { to: '#', image: '/images/404-image.png', title: 'link de prueba' },
  ],
};
export default FrequentLinkCard;
