import Api from 'api/Api';
import { Modal } from 'components/';
import useAuth from 'lib/hooks/useAuth';
import Script from 'next/script';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { userState } from '../../atoms/UserAtom';

interface ServiceInput {
  title: string;
  img: string;
  description: string;
  price: number;
  bgColor: string;
  onMoreInfoClick?: () => void;
}

export const PopUpHub = ({ show, onClose, closeable = true, hubIndex }) => {
  const [user, setUser] = useRecoilState(userState);
  const [closed, setClosed] = useState(false);
  const [send, setSend] = useState(false);
  const [date, setDate] = useState<Date>();
  const [hour, setHour] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [backendMessage, setBackendMessage] = useState<{ sucess: boolean; message: string }>();
  const [ableToSend, setAbleToSend] = useState(true);
  const { getToken } = useAuth();

  const DataServices: ServiceInput[] = [
    {
      title: 'Media Hub',
      img: '',
      description: 'Gestión editorial, gráfica y estratégica de redes sociales.',
      price: 6300,
      bgColor: '#56c6eb',
    },
    {
      title: 'Ad Hub',
      img: '',
      description: 'Gestión, implementación y optimización de campañas publicitarias.',
      price: 4750,
      bgColor: '#d33275',
    },
    {
      title: 'Design Hub',
      img: '',
      description: 'Producción de videos, fotografías, flyers, tarjetas de presentación y más.',
      price: 4250,
      bgColor: '#be2b33',
    },
    {
      title: 'Web Hub',
      img: '',
      description: 'Creamos tu página web inmobiliaria.',
      price: 10000,
      bgColor: '#141b37',
    },
    {
      title: 'App Hub',
      img: '',
      description: 'Creamos tu App inmibiliaria para android y ios.',
      price: 10000,
      bgColor: '#f19469',
    },
    {
      title: 'Bot Hub',
      img: '',
      description: 'Automatizamos todos los canales de comunicación con tus clientes.',
      price: 2250,
      bgColor: '#ee6865',
    },
  ];

  const sendFunction = async () => {
    setSend(true);
    try {
      setAbleToSend(false);
      const { data } = await Api(getToken()).post('users/requestService', {
        date: date?.getTime(),
        hour: hour,
        message: message,
        service: DataServices[hubIndex].title,
      });
      // console.log(data);
      setBackendMessage({ sucess: true, message: data?.message });
      // if (data.membership) setActualSubscription(data);
    } catch (e) {
      // console.log(e?.response.data.message);
      setBackendMessage({ sucess: false, message: e?.response.data.message[0] });
      setAbleToSend(true);
      return;
    }
    // console.log('here');
    // console.log(send);
  };
  // console.log(closed, 'closed');
  // console.log(send, 'send');

  if (closed == true && send == false) {
    try {
      if (_learnq) {
        _learnq.push([
          'identify',
          {
            $email: user.email,
          },
        ]);
        const track = [
          'track',
          `Hub ${hubIndex}`,
          {
            $email: user.email,
            $first_name: user.name,
          },
        ];

        _learnq.push(track);
      }
      // console.log(_learnq, user.email);
      // console.log('resourses', resources[0].id);
    } catch (e) {}
    // console.log('send', hubIndex, 'hubIndex');
  } else if (closed == true && send == true) {
    // console.log('not send');
  }

  const container = 'block text-center bg-[#fff]  w-[36.3em] msm:w-[18em] h-[21em] msm:h-[29em] ml-auto mr-auto rounded-lg shadow-card';
  const header = 'flex items-center justify-center h-[2.3em] bg-primary text-lg rounded-tl-lg rounded-tr-lg';
  const closeF = 'float-right mb-3 mr-2 transition-all transform';
  const closeButton = ' text-3xl text-[#fff] transform  rounded-full cursor-pointer border-primary  hover:border-white hover:text-gray-300';
  const sendButton: string = [
    'text-[#fff] rounded-xl text-base py-1 px-6 msm:w-full',
    ableToSend ? ' bg-primary ' : ' bg-gray-400 pointer-events-none ',
  ].join(' ');

  return (
    <>
      <Modal.Modal crossDisplay={'absolute'} show={show} onClose={() => onClose()} className='w-full sm:w-10/12 md:w-3/4'>
        <div className='self-center'>
          <aside className='flex justify-center mx-auto mb-2'>
            <div className={container}>
              <div className={header}>
                <span className='text-[#fff] ml-auto mr-auto'>{DataServices[hubIndex].title} </span>
                <div
                  className={closeF}
                  onClick={() => {
                    closeable ? onClose?.(setClosed(true)) : null;
                  }}
                >
                  <div className=''></div>
                  <p className={closeButton}>x</p>
                </div>
              </div>

              <div className='flex flex-col w-full px-7 msm:px-5'>
                <b className='my-3 text-base text-primary font-futura-light msm:text-sm'>
                  Agenda una Video Llamada con nuestro equipo para conocer más sobre este servicio.
                </b>
                {/* <div className='h-12' /> */}
                <div className='grid grid-cols-2 gap-3 msm:grid-cols-1'>
                  <div>
                    <p className='float-left mb-1 text-base text-gray-600 font-futura-light'>Fecha de Llamada</p>
                    <input
                      type='date'
                      name='date'
                      className='border-[#17528a] border-[1px] rounded-lg w-full'
                      onChange={(e) => setDate(new Date(e.target.value.replaceAll('-', '/')))}
                    />
                  </div>
                  <div>
                    <p className='float-left mb-1 text-base text-gray-600'>Horario</p>
                    <input
                      type='time'
                      name='time'
                      className='border-[#17528a] border-[1px] rounded-lg w-full'
                      onChange={(e) => setHour(e.target.value)}
                    />
                  </div>
                </div>
                <div className='mt-2'>
                  <p className='float-left mb-1 text-base text-gray-600'>Mensaje</p>
                  <textarea
                    className='border-[#17528a] border-[1px] rounded-lg resize-none w-full msm:h-[6em]'
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div className='flex flex-col justify-center'>
                  {backendMessage && (
                    <p className={['font-futura text-base ', !backendMessage.sucess ? ' text-red-500 ' : ' text-green-400'].join(' ')}>
                      {backendMessage.message}
                    </p>
                  )}
                  <div className='mt-3'>
                    <button disabled={!ableToSend} className={sendButton} onClick={sendFunction}>
                      Enviar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Script
              src='https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Rruh78'
              onLoad={() => {
                // klaviyoArticle();
              }}
            />
          </aside>
        </div>
      </Modal.Modal>
    </>
  );
};
