import React from 'react';

const PhoneCallIcon = ({ className, ...props }) => {
  return (
    <svg className={className} viewBox='0 0 512 512' xmlSpace='preserve' {...props}>
      <g>
        <g>
          <path
            fill='currentColor'
            d='M453.109,304.621c-0.062-0.008-0.124-0.017-0.186-0.025c-21.096-2.782-41.9-7.965-61.789-15.387    c-24.732-9.296-52.859-3.301-71.651,15.281c-0.028,0.027-0.055,0.055-0.083,0.082l-18.595,18.595    c-45.437-28.548-83.902-67.013-112.45-112.45l18.595-18.595c0.028-0.027,0.055-0.054,0.082-0.082    c18.581-18.79,24.58-46.915,15.298-71.604c-7.438-19.935-12.622-40.739-15.404-61.836c-0.008-0.063-0.017-0.127-0.026-0.189    C202.179,24.998,173.337,0,139.657,0c-0.184,0-0.374,0.001-0.559,0.002H68.32c-2.008,0.002-4.041,0.095-6.048,0.276    C44.205,1.911,27.855,10.482,16.234,24.411C4.613,38.342-0.888,55.963,0.745,74.03c0.01,0.108,0.02,0.215,0.032,0.323    c8.052,75.828,34.215,149.736,75.663,213.741c37.615,59.17,88.763,110.318,147.933,147.933    c63.709,41.296,137.275,67.459,212.749,75.659c0.122,0.014,0.245,0.026,0.369,0.037c2.028,0.184,4.091,0.277,6.145,0.277    c0.082,0,0.164,0,0.247-0.002c18.14-0.075,35.167-7.208,47.942-20.088c12.775-12.879,19.77-29.963,19.697-48.02v-70.616    C512.236,338.916,487.17,309.427,453.109,304.621z M470.164,372.529c-0.005,0.172-0.006,0.342-0.006,0.514v70.935    c0.029,7.092-2.705,13.77-7.7,18.805c-4.995,5.035-11.651,7.824-18.734,7.853c-0.76,0.004-1.568-0.029-2.342-0.095    c-69.032-7.534-136.317-31.486-194.583-69.271c-0.053-0.035-0.107-0.069-0.161-0.103c-54.137-34.4-100.935-81.198-135.335-135.335    c-0.032-0.051-0.064-0.101-0.097-0.153C73.277,207.132,49.323,139.531,41.929,70.166c-1.235-14.524,9.532-27.379,24.062-28.691    c0.787-0.071,1.583-0.108,2.349-0.108h70.85c0.154,0,0.311-0.002,0.465-0.002c13.14,0,24.398,9.735,26.277,22.755    c3.193,24.147,9.13,47.958,17.663,70.823c3.63,9.656,1.297,20.634-5.942,27.979l-29.943,29.944    c-6.583,6.583-7.957,16.754-3.354,24.847c35.443,62.332,87.123,114.011,149.454,149.455c8.093,4.6,18.265,3.23,24.848-3.354    l29.942-29.943c7.346-7.239,18.325-9.572,28.026-5.924c22.818,8.515,46.632,14.451,70.78,17.644    C460.72,347.508,470.498,359.076,470.164,372.529z'
          />
        </g>
      </g>
    </svg>
  );
};
export default PhoneCallIcon;
