import React from 'react';

const InstagramIcon = ({ ...props }) => {
  return (
    <svg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M37.77 0H6.56999C4.82823 0.00790926 3.16021 0.704005 1.92952 1.93657C0.698829 3.16913 0.00526018 4.83821 0 6.57999V37.77C0.00526018 39.5118 0.698829 41.1809 1.92952 42.4134C3.16021 43.646 4.82823 44.3421 6.56999 44.35H37.77C39.5118 44.3421 41.1798 43.646 42.4105 42.4134C43.6412 41.1809 44.3347 39.5118 44.34 37.77V6.57999C44.3347 4.83821 43.6412 3.16913 42.4105 1.93657C41.1798 0.704005 39.5118 0.00790926 37.77 0ZM40.65 37.77C40.65 38.5338 40.3466 39.2664 39.8065 39.8065C39.2664 40.3466 38.5338 40.65 37.77 40.65H6.56999C5.80617 40.65 5.07363 40.3466 4.53352 39.8065C3.99342 39.2664 3.69 38.5338 3.69 37.77V6.57999C3.69 5.81616 3.99342 5.08364 4.53352 4.54353C5.07363 4.00343 5.80617 3.7 6.56999 3.7H37.77C38.5338 3.7 39.2664 4.00343 39.8065 4.54353C40.3466 5.08364 40.65 5.81616 40.65 6.57999V37.77Z'
        fill='currentColor'
      />
      <path
        d='M22.17 11C19.9762 11 17.8317 11.6507 16.0077 12.8697C14.1838 14.0887 12.7623 15.8213 11.9232 17.8484C11.0842 19.8754 10.8651 22.1057 11.2938 24.2572C11.7226 26.4088 12.7798 28.3848 14.3317 29.9353C15.8837 31.4859 17.8607 32.5413 20.0126 32.9681C22.1645 33.3949 24.3947 33.1739 26.4209 32.3329C28.4472 31.492 30.1785 30.069 31.3959 28.244C32.6133 26.4189 33.262 24.2738 33.26 22.08C33.2574 19.1405 32.0878 16.3223 30.0083 14.2446C27.9288 12.167 25.1095 11 22.17 11ZM22.17 29.47C20.7084 29.47 19.2796 29.0366 18.0644 28.2246C16.8491 27.4125 15.9019 26.2584 15.3426 24.908C14.7832 23.5577 14.6369 22.0718 14.922 20.6383C15.2072 19.2048 15.911 17.888 16.9445 16.8545C17.978 15.821 19.2948 15.1171 20.7283 14.832C22.1618 14.5469 23.6477 14.6932 24.9981 15.2525C26.3484 15.8119 27.5026 16.7591 28.3146 17.9743C29.1266 19.1896 29.56 20.6184 29.56 22.08C29.56 24.04 28.7814 25.9196 27.3955 27.3055C26.0097 28.6914 24.13 29.47 22.17 29.47V29.47Z'
        fill='currentColor'
      />
      <path
        d='M32.77 35.54C34.2998 35.54 35.54 34.2998 35.54 32.77C35.54 31.2402 34.2998 30 32.77 30C31.2402 30 30 31.2402 30 32.77C30 34.2998 31.2402 35.54 32.77 35.54Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default InstagramIcon;
