import React from 'react';

const FacebookIcon = ({ ...props }) => {
  return (
    <svg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M37.73 2H8.52C4.91911 2 2 4.9191 2 8.52V37.73C2 41.3309 4.91911 44.25 8.52 44.25H37.73C41.3309 44.25 44.25 41.3309 44.25 37.73V8.52C44.25 4.9191 41.3309 2 37.73 2Z'
        stroke='currentColor'
        strokeWidth='2.5'
        strokeMiterlimit='10'
      />
      <path
        d='M31.3 7.36V13.48L26.18 13.81V16.63H31.63L30.22 24.16H26.18V39.31H23.96V21.95H28.29L29.04 18.82H23.96V13.95L24.13 13.57L24.4 13.02L24.64 12.65L25.05 12.12L25.71 11.74L26.26 11.55L27.27 11.38L29.4 11.36L29.36 8.93999L28.62 8.62L27.49 8.32L25.9 8.16L23.96 8.14L22.95 8.63L21.86 9.16L21.4 9.66L20.82 10.42L20.52 11.04L20.09 12.33L19.98 12.95L19.87 13.85V15.17V18.82H16.74V22.05L19.84 22.02L19.87 39.05L17.77 39.14V24.05L14.62 24.14V16.7H17.77V13.8L17.92 12.18L18.21 11.13L18.81 9.49L19.62 8.42L20.02 8.06L20.72 7.36L21.12 7.14L21.74 6.78L22.51 6.43L23.22 6.23L24.14 6.13L24.95 6H26L26.85 6.13L27.48 6.22L28.22 6.41L28.81 6.49L29.43 6.7L30.41 7.05L31.3 7.36Z'
        fill='currentColor'
      />
    </svg>
  );
};
export default FacebookIcon;
