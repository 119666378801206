import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import getConfig from 'next/config';
import { dmsToDef } from 'lib/utils';

const GMap = ({ zoom, lat, lng, containerStyle, className }) => {
  const { publicRuntimeConfig } = getConfig();
  const containerStyleDefault = {
    width: '400px',
    height: '400px',
  };

  const center = {
    lat: String(lat).includes('N') ? dmsToDef(lat) : +lat || 0,
    lng: String(lng).includes('W') ? dmsToDef(lng) : +lng || 0,
  };

  return (
    <div>
      <LoadScript googleMapsApiKey={publicRuntimeConfig.GEOCODE_API_KEY}>
        <GoogleMap mapContainerClassName={className} mapContainerStyle={containerStyle || containerStyleDefault} center={center} zoom={zoom || 8}>
          <></>
          <Marker position={center} icon='/SVG/map-marker-odigos.svg' />
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default React.memo(GMap);
