import React from 'react';

const index = () => {
  if (typeof window !== 'undefined') {
    document.querySelector('body')?.scrollIntoView();
  }
  return (
    <div className='absolute top-0 bottom-0 left-0 right-0 z-50 flex flex-col items-center justify-center w-full h-full overflow-hidden bg-gray-300 opacity-75'>
      <div className='w-12 h-12 mb-4 border-4 border-gray-200 rounded-full animate-spin border-t-blue-500'></div>
      <h2 className='text-xl font-semibold text-center text-black'>Cargando...</h2>
    </div>
  );
};

export default index;
