import React from 'react';
import classes from './Input.module.css';
interface InputProps {
  label?: string;
  name: string;
  type?: 'text' | 'email' | 'password' | 'number' | 'checkbox';
  placeholder?: string;
  labelClassName?: string;
  className?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ label, name, type = 'text', placeholder = '', labelClassName, className = '', ...props }, ref) => {
    return (
      <div id='input' className={type === 'checkbox' ? classes.inputContainerInline : classes.inputContainer}>
        {label && type !== 'checkbox' && (
          <label htmlFor={name} className={labelClassName}>
            {label}
          </label>
        )}
        <input ref={ref} name={name} type={type} className={[classes.input, className].join('')} placeholder={placeholder} {...props} />
        {type === 'checkbox' && (
          <label htmlFor={name} className={labelClassName}>
            {label}
          </label>
        )}
      </div>
    );
  }
);

export default Input;
