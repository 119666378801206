import Api from 'api/Api';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useRef, useState } from 'react';

const PopUpUpdateProfile = ({ show, onClose, updateToast, agency, user }) => {
  const ref = useRef<HTMLInputElement>(null);
  const { getToken } = useAuth();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [newPhoto, setNewPhoto] = useState('');
  const [dataImg, setDataImg] = useState<FormData>();

  useEffect(() => {
    setUsername(user.name);
    setEmail(user.email);
    setPhone(user.phone);
  }, [agency, user]);

  const finishUpdateData = async () => {
    try {
      const updateUserData = {
        name: username,
        email: email,
        phone: phone,
      };
      const { data } = await Api(getToken()).patch('/users', updateUserData);
      // console.log(data);
      if (data && dataImg) {
        // console.log('hay imagen: ', dataImg);
        await Api(getToken()).post('/agencies/upload', dataImg, { headers: { Accept: 'multipart/form-data' } });
      }
      updateToast('Operación realizada con éxito', 'success');
      onClose?.();
    } catch (e) {
      updateToast(e?.response.data.message, 'error');
      // console.log(e);
    }
  };

  const handleImageUpload = async (e) => {
    const formData = new FormData();
    if (!agency) return;
    formData.append('image', e.target.files[0]);
    formData.append('agency', JSON.stringify(agency));
    try {
      const { data } = await Api(getToken()).post('/agencies/upload-temp', formData, { headers: { Accept: 'multipart/form-data' } });
      if (data) {
        setDataImg(formData);
        setNewPhoto(data.imgPath);
      }
    } catch (e) {}
  };

  const container = 'absolute mt-[20vh] w-[100%] h-[70vh]  z-40';
  const popUp =
    'block text-center bg-[#fff]  w-[32em] msm:w-[18em] h-[14.5em] msm:h-[25em] ml-auto mr-auto rounded-lg shadow-card border-[#80a8b383]  border-[1px] text-xl';
  const saveButton = 'bg-primary text-[#fff] rounded-xl w-[13.5em]  text-base py-[0.1em] px-0 shadow-card mr-auto ml-auto mt-6 msm:w-full';
  const popUpHeader = 'flex items-center justify-center h-[2.3em] bg-primary text-lg rounded-tl-lg rounded-tr-lg';
  const uploadImg = 'ml-3 text-base underline cursor-pointer text-primary font-semibold';
  const image = 'z-0 w-20 h-20 border-[2px] rounded-full shadow-lg cursor-pointer border-primary';
  const imgText = 'inline text-base float-left ml-7 text-primary font-semibold';
  const inputSpan = 'text-center font-semibold text-sm text-primary float-left';
  const inputStyle = 'border-[#17528a] border-[1px] rounded-lg w-full';

  return show ? (
    <>
      <div className={container}>
        <div className={popUp}>
          <div className={popUpHeader}>
            <span className='text-[#fff] ml-auto mr-auto'>Actualizar Perfil</span>
            <button className=' mr-[0.5em] text-[#fff]' onClick={onClose}>
              X
            </button>
          </div>

          <div className='grid grid-cols-2 mx-6 msm:grid-cols-1'>
            <div className=' mt-2 text-primary mr-10 msm:mr-[0px]'>
              <div className='mt-4'>
                <span className={inputSpan}>Nombre</span>
                <input type='text' name='password' className={inputStyle} value={username} onChange={(e) => setUsername(e.target.value)} />
              </div>
              <div className='mt-4'>
                <span className={inputSpan}>Correo Electrónico</span>
                <input type='text' name='password' className={inputStyle} value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className='mt-4'>
                <span className={inputSpan}>Teléfono</span>
                <input type='text' name='password' className={inputStyle} value={phone} onChange={(e) => setPhone(e.target.value)} />
              </div>
            </div>

            <div>
              <div className='block mt-4 w-full h-[2em]'>
                <span className={imgText}>Imagen</span>
              </div>
              <div className='flex justify-center'>
                <img src={newPhoto || agency?.image || '/images/placeholder-image.jpg'} alt='Brand logo' className={image} />
                <input id='image_upload' type='file' className='hidden' ref={ref} onChange={handleImageUpload} />
                <button type='button' className={uploadImg} onClick={() => ref.current?.click()}>
                  Seleccionar Imagen
                </button>
              </div>

              <div className='mt-10 msm:mt-7'>
                <button onClick={() => finishUpdateData()} className={saveButton}>
                  Guardar Cambios
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div />
  );
};

export default PopUpUpdateProfile;
