import Api from 'api/Api';
import { Button, Label } from 'components/';
import styles from 'components/Input/Input.module.css';
import useAuth from 'lib/hooks/useAuth';
import { ChangeEvent, FC, useRef, useState } from 'react';
type AssetType = 'image' | 'pdf' | 'pdf|excel';
interface Asset {
  id: number;
  name: string;
  path: string;
}
interface AssetSelectorProps {
  text: string;
  type: AssetType;
  initialAsset?: Asset;
  onAssetUploaded: (assetId: number) => void;
  onAssetUploadedError: (error: string) => void;
  onAssetDeleted: () => void;
}
const getAssetBackendType = (type: AssetType): string => {
  switch (type) {
    case 'image':
      return 'image';
    case 'pdf':
    case 'pdf|excel':
      return 'file';
  }
};
const getInputAcceptString = (type: AssetType): string => {
  switch (type) {
    case 'image':
      return 'image/*';
    case 'pdf':
      return '.pdf';
    case 'pdf|excel':
      return '.pdf, .xlsx, .xls';
  }
};
export const AssetSelector: FC<AssetSelectorProps> = ({ text, type, onAssetUploaded, onAssetUploadedError, onAssetDeleted, initialAsset }) => {
  const [asset, setAsset] = useState<Asset | undefined>(initialAsset);
  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { getToken } = useAuth();
  const assetType = getInputAcceptString(type);
  const uploadFile = async (file: File) => {
    try {
      setUploading(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', getAssetBackendType(type));
      const { data } = await Api(getToken()).post('assets', formData);
      data.id = +data.id;
      setAsset(data);
      onAssetUploaded(data.id);
    } catch (error) {
      onAssetUploadedError(error?.message || error);
    } finally {
      setUploading(false);
    }
  };
  const deleteFile = async () => {
    try {
      setDeleting(true);
      await Api(getToken()).delete(`assets/${asset?.id}`);
      setAsset(undefined);
      onAssetDeleted();
    } catch (error) {
      onAssetUploadedError(error?.message || error);
    } finally {
      setDeleting(false);
    }
  };
  const handleDelete = () => {
    deleteFile();
    inputRef.current?.files && (inputRef.current.files = null);
  };
  const handleClick = () => {
    inputRef?.current?.click();
  };
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    uploadFile(file);
  };
  return (
    <div className={styles.inputContainer}>
      <Label>{text}</Label>
      <div className='flex mmm:flex-col flex-row gap-5 justify-between items-center '>
        {asset ? (
          <div className=' w-full mmmm:flex-grow  truncate'>
            <a className=' truncate' href={asset.path} target='_blank' rel='noopener noreferrer'>
              {asset.name}
            </a>
          </div>
        ) : (
          <span>No se ha seleccionado ningún archivo</span>
        )}
        <div className='flex mss:flex-col msm:w-full flex-row gap-5 '>
          <Button onClick={handleDelete} label='Eliminar' variant='danger' disabled={!asset} onLoading={deleting} />
          <Button
            className='outline-none focus:outline-none border border-primary hover:bg-primary hover:text-white'
            onClick={handleClick}
            label='Elegir archivo'
            onLoading={uploading}
          />
          <input type='file' className='hidden' accept={assetType} ref={inputRef} onChange={handleFileChange} />
        </div>
      </div>
    </div>
  );
};
