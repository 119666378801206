import { FC } from 'react';

interface MembershipInput {
  membershipId?: number;
  title?: string;
  price?: number;
  index?: number;
  renewPrice?: number;
  features?: string[];
  onShopClick?: () => void;
  onMoreInfoClick?: () => void;
  isOwn?: boolean;
  highlight?: boolean;
}
const MembershipCard: FC<MembershipInput> = ({
  title,
  price,
  renewPrice,
  features,
  onShopClick,
  onMoreInfoClick,
  isOwn = false,
  highlight = false,
}) => {
  return (
    <>
      <div className='flex flex-none relative w-64 h-80 border-gray-200 border shadow-card rounded-xl transform motion-safe:hover:scale-105 transition-all duration-200'>
        <div className='flex flex-col w-full'>
          <div className='flex flex-none w-full px-4'>
            <div className='flex flex-none justify-center w-full py-2 border-b border-primary px-2 '>
              <p className='text-2xl font-futura text-primary  '>{title}</p>
            </div>
          </div>
          <div className='flex flex-none w-full pt-6 pb-2 px-12 justify-center'>
            <div
              className={[
                ' flex flex-none w-full h-12 border-primary border rounded-xl justify-center items-center shadow-card ',
                highlight ? ' bg-primary ' : ' ',
              ].join(' ')}
            >
              <p className={[' text-2xl font-futura-bold ', highlight ? ' text-white ' : ' text-primary '].join(' ')}>${price} MXN</p>
            </div>
          </div>
          {renewPrice ? (
            <div className='flex flex-none w-full justify-center pb-4'>
              <p className='font-futura-light text-[1em] text-primary'>mxn {renewPrice}/mes al renovar</p>
            </div>
          ) : (
            <div className='pb-12' />
          )}

          <div className='flex flex-none w-full px-4'>
            <ul className='list-disc text-[0.8em]'>
              {features &&
                features.map((val, idx) => (
                  <p className='text-[0.8em] text-justify text-primary font-futura font-semibold ' key={idx}>
                    {val}
                  </p>
                ))}
            </ul>
          </div>
          <div className='flex flex-row absolute bottom-0 w-full px-6 pb-2'>
            {isOwn ? (
              <div />
            ) : (
              onShopClick && (
                <p className='cursor-pointer flex flex-none text-lg text-primary font-semibold  font-champagne uppercase' onClick={onShopClick}>
                  Comprar
                </p>
              )
            )}
            <div className='flex flex-initial w-full' />
            {onMoreInfoClick && (
              <p
                className='cursor-pointer flex flex-none text-lg text-primary font-semibold font-champagne border-b border-primary uppercase'
                onClick={onMoreInfoClick}
              >
                Más info
              </p>
            )}
          </div>
          {isOwn ? (
            <div className='flex flex-none absolute top-[-32px] left-[-32px] w-16 h-16 bg-primary rounded-full justify-center items-center'>
              <p className=' text-white uppercase text-sm'>Actual</p>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </>
  );
};
export default MembershipCard;
