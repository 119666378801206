import { VideoPlayerPopUp } from 'components/';
import { FC, useState } from 'react';

export interface MiniVideoCardProps {
  email?: string;
  user?: string;
  title: string;
  description?: string;
  duration: string;
  videoUrl?: string;
  assetName?: string;
  assetUrl?: string;
  seen?: boolean;
  thumbnail?: string;
  isPreview?: boolean;
  videoId?: number;
  onClickAction?: () => void;
  onClickVideoId?: () => void;
}

const MiniVideoCard: FC<MiniVideoCardProps> = ({
  title,
  description,
  duration,
  videoUrl,
  assetName,
  assetUrl,
  thumbnail,
  seen = false,
  isPreview = false,
  videoId,
  onClickAction,
  onClickVideoId,
}) => {
  const [showVideModal, setShowVideModal] = useState(false);

  return (
    <>
      <button
        onClick={() => {
          setShowVideModal(true && !isPreview);
          onClickAction?.();
          onClickVideoId?.();
        }}
        className={[' ', isPreview ? ' pointer-events-none ' : ' '].join(' ')}
      >
        <div className='w-full h-full transition-all duration-100 transform bg-white border-2 shadow-card border-gray-50 motion-safe hover:scale-105'>
          <div className='flex flex-col h-full gap-1 p-4'>
            <div className='relative grow'>
              <img className='w-full' src={thumbnail || 'https://storage.googleapis.com/fotos-odigos-dev/1625853585318arq9.jpg'} alt='Title' />
              <Time duration={duration} />
            </div>
            <h1 className='justify-start text-2xl text-left text-primary font-futura'>{title}</h1>
            <p className='text-lg text-left text-gray-400'>{description}</p>
            {seen ? (
              <div className='flex flex-row items-end justify-end flex-grow'>
                <p className='flex-none text-left text-gray-400'>Visto</p>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </button>
      {videoUrl && (
        <VideoPlayerPopUp
          videoId={videoId}
          onClose={() => setShowVideModal(false)}
          show={showVideModal}
          title={title}
          videoUrl={videoUrl}
          description={description}
          assetName={assetName}
          assetUrl={assetUrl}
        />
      )}
    </>
  );
};

export default MiniVideoCard;
const Time: FC<{ duration: string }> = ({ duration }) => {
  return (
    <div className='absolute h-6 bg-gray-900 rounded-lg w-min bg-opacity-80 bottom-2 right-2'>
      <p className='px-2 text-white'>{duration}</p>
    </div>
  );
};
