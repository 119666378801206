import Api from 'api/Api';
import { ConektaIframe, MembershipCard } from 'components/';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';

const PopUpAddChange = ({ show, onClose, membershipData }) => {
  const { getToken } = useAuth();
  const [customer, setCustomer] = useState();
  const [checkoutId, setCheckoutId] = useState(undefined);
  const [cardTokenized, setCardTokenized] = useState(false);

  const getCustomer = async () => {
    try {
      const { data } = await Api(getToken()).get('memberships/customer');
      setCustomer(data);
    } catch (e) {
      // setErrorMessage(e?.message || 'Un error inesperado ha ocurrido');
    }
  };
  const createCheckoutToken = async () => {
    try {
      const { data } = await Api(getToken()).post('memberships/createCheckoutToken');
      setCheckoutId(data.checkoutId);
      // console.log(data.checkoutId);
    } catch (e) {
      // setErrorMessage(e?.message || 'Un error inesperado ha ocurrido');
    } finally {
      // setLoading(false);
    }
  };
  const handleCardTokenized = async (tokenizedData) => {
    try {
      // const { data } = await Api(getToken()).post(`memberships/${customer ? 'addPaymentSource' : 'createCustomerWithToken'}`, {
      //   checkoutId: tokenizedData.id,
      // });
      const { data } = await Api(getToken()).post(`memberships/${customer ? 'setDefinitiveCard' : 'createCustomerWithToken'}`, {
        checkoutId: tokenizedData.id,
      });
      // if (data) console.log('Datos procesados');
      if (data) setCardTokenized(true);
    } catch (e) {
      // console.log(e);
      // setErrorMessage(e?.message || 'Un error inesperado ha ocurrido');
    } finally {
      // setCheckoutId(undefined);
      // setPopUpAddChange(false);
    }
  };

  useEffect(() => {
    const getdata = async () => {
      await getCustomer();
      await createCheckoutToken();
    };
    getdata();
  }, []);

  const popUp = 'absolute mt-[-2em] msm:mt-[5vh] w-[100%] h-[70vh] mss:h-[70em] z-40';
  const popUpContainer =
    'block text-center bg-[#fff] w-[46em]  mlg:w-full msm:w-[20em] h-[50.5em] mss:h-[60em]  ml-auto mr-auto rounded-lg shadow-card border-[#80a8b383]  border-[1px] text-xl';
  const popUpHeader = 'flex items-center justify-center h-[2.3em] bg-primary text-lg rounded-tl-lg rounded-tr-lg';
  const popUpClose = ' mr-[0.5em] text-[#fff] lg: pr-9';
  const content = 'grid grid-cols-2 ml-5 msm:grid-cols-1 mmd:ml-0 ';
  const successMsg = 'text-primary text-sm text-left font-semibold w-[18em] ml-auto mr-auto mt-2';

  return show ? (
    <>
      <div className={popUp}>
        <div className={popUpContainer}>
          <div className={popUpHeader}>
            <span className='text-[#fff] ml-auto mr-auto'>Actualizar Método de Pago</span>
            <button className={popUpClose} onClick={onClose}>
              X
            </button>
          </div>
          <div className={content}>
            {cardTokenized ? (
              <div className='mt-9'>
                <img className='inline w-[5em]' src='/images/checkmark.png' />
                <div className={successMsg}>
                  <p className='float-left'>Tu tarjeta se agregó correctamente</p>
                </div>
              </div>
            ) : (
              <div className='flex flex-1 sm: h-[35rem] mss:h-[45rem] '>
                {checkoutId && (
                  <ConektaIframe
                    checkoutId={checkoutId}
                    onCardTokenized={handleCardTokenized}
                    onError={(error) => {
                      // setErrorMessage(error.message_to_purchaser);
                    }}
                  />
                )}
              </div>
            )}

            <div className=' px-20  mmd:grid mmd:grid-rows-0 mmd:px-0 mmd:ml-auto mmd:mr-auto'>
              <div className='block h-[2em] w-[100%] msm:inline-block'>
                <span className='font-semibold text-base text-primary float-left msm:mt-2'>Tu Plan</span>
              </div>
              <div className='mt-6'>
                <MembershipCard
                  title={membershipData.type}
                  price={membershipData.monthly_plan ? membershipData.monthly_plan.monthly_price : 0}
                  features={membershipData.features}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div />
  );
};

export default PopUpAddChange;
