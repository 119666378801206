import { ConektaCardTokenCreatedEvent, ConektaCardTokenFailedEvent } from 'lib/types/conekta';
import getConfig from 'next/config';
import Script from 'next/script';
import { FC, useEffect } from 'react';

const { publicRuntimeConfig } = getConfig();

interface ConektaIframeProps {
  checkoutId: string;
  onCardTokenized: (data: ConektaCardTokenCreatedEvent) => void;
  onError: (error: ConektaCardTokenFailedEvent) => void;
}
const conektaIframeOptions = {
  theme: 'default',
  styles: {
    inputType: 'basic',
    buttonType: 'rounded',
    fontSize: 'compact', // 'baseline' | 'compact',
    states: {
      empty: { borderColor: '#17528a' },
      invalid: { borderColor: '#FF00E0' },
      valid: { borderColor: '#17528a' },
    },
  },
  languaje: 'es',
  button: {
    colorText: '#ffffff',
    backgroundColor: '#053D50 !important',
  },
  iframe: {
    colorText: '#053D50',
    backgroundColor: '#FFFFFF',
  },
};
export const ConektaIframe: FC<ConektaIframeProps> = ({ checkoutId, onCardTokenized, onError }) => {
  useEffect(() => {
    loadConekta();
  }, []);
  const loadConekta = () => {
    try {
      ConektaCheckoutComponents.Card({
        targetIFrame: '#conektaIframeContainer',
        allowTokenization: true,
        checkoutRequestId: checkoutId,
        publicKey: publicRuntimeConfig.CONEKTA_PUBLIC_KEY,
        options: conektaIframeOptions,
        onCreateTokenSucceeded: onCardTokenized,
        onCreateTokenError: onError,
      });
    } catch (e) {}
  };
  return (
    <>
      <div
        className='md:h-[40em] msm:h-[28.5em] '
        id='conektaIframeContainer'
        style={{
          width: '100%',
          height: '40em',
        }}
      ></div>
      <Script
        id='conekta-script'
        src='https://pay.conekta.com/v1.0/js/conekta-checkout.min.js'
        onLoad={() => {
          loadConekta();
        }}
      />
    </>
  );
};
