import React from 'react';
interface TextFieldProps {
  name: string;
  cols?: number;
  rows?: number;
  className?: string;
  placeholder?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
const TextField = React.forwardRef<HTMLTextAreaElement, TextFieldProps>(
  ({ name, cols = 20, rows = 4, className = '', placeholder = 'describe aquí', ...props }, ref) => {
    return (
      <textarea
        ref={ref}
        className={['text-xl font-futura-med outline-none mx-0 my-4 px-2 text-primary border border-primary w-full', className].join(' ')}
        name={name}
        cols={cols}
        rows={rows}
        placeholder={placeholder}
        {...props}
      ></textarea>
    );
  }
);

export default TextField;
