import React, { FC } from 'react';

interface MembershipInput {
  membershipId?: number;
  title: string;
  price: number | undefined;
  index?: number;
  renewPrice?: number;
  features?: string[];
  onShopClick?: () => void;
  onMoreInfoClick?: () => void;
  isOwn?: boolean;
  highlight?: boolean;
}

export const MembershipDeal: FC<MembershipInput> = ({ title, price, renewPrice, highlight = false }) => {
  return (
    <>
      <div className='flex flex-col flex-none mb-6 '>
        {highlight && (
          <p className='text-4xl text-center text-primary font-futura-heavy'>
            <b>{title}</b>
          </p>
        )}
        <button className='px-12 py-1  bg-primary rounded-xl msm:w-full'>
          <p className='text-2xl text-white font-futura-bold'>
            <b>${price} MXN</b>
          </p>
        </button>
        <p className='text-center text-primary font-futura-light'>mx ${renewPrice}/mes al renovar </p>
      </div>
    </>
  );
};
