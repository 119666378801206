import { FC, ReactNode } from 'react';
import Spinner from '../Spinner';
type ButtonSize = 'small' | 'medium' | 'large';
type ButtonVariant = 'primary' | 'danger' | 'default';
interface ButtonProps {
  primary?: boolean;
  size?: ButtonSize;
  label?: string | ReactNode;
  onClick?: () => void;
  onLoading?: boolean;
  variant?: ButtonVariant;
  className?: string;
  disabled?: boolean;
}
const mode: Record<ButtonVariant, string> = {
  primary: 'text-white bg-primary uppercase shadow-lg',
  danger: 'text-white bg-red-600 uppercase shadow-lg',
  default: 'text-black bg-white uppercase',
};
const sizes: Record<ButtonSize, string> = {
  small: 'text-sm py-2 px-4',
  medium: 'text-md py-2.5 px-5',
  large: 'text-lg py-3 px-6',
};
const Button: FC<ButtonProps> = ({
  disabled = false,
  onLoading = false,
  primary = false,
  variant = 'default',
  size = 'medium',
  label,
  className,
  ...props
}) => {
  return (
    <button
      type='button'
      className={[
        'rounded-lg leading-none',
        sizes[size],
        mode[primary ? 'primary' : variant],
        onLoading || disabled ? 'opacity-70 bg-gray-500 pointer-events-none' : '',
        className,
      ].join(' ')}
      {...props}
      disabled={onLoading || disabled}
    >
      {label} {onLoading && <Spinner />}
    </button>
  );
};

export default Button;
