import Link from 'next/link';
import { FacebookIcon, InstagramIcon, TickTokIcon, TwitterIcon, YoutubeIcon } from '../../components/Svgs';
const Footer = () => {
  return (
    <div className='flex flex-col tracking-wider bg-primary'>
      <div className='grid grid-cols-1 gap-3 my-4 text-white md:grid-cols-4 md:gap-0'>
        <div className='text-sm text-center uppercase font-futura-light'>
          <h3 className='my-2 text-lg font-futura-med'>Información</h3>
          <ul className='leading-6'>
            <li>
              <a href='https://www.odigos.studio'>Eventos</a>
            </li>
          </ul>
        </div>
        <div className='text-sm text-center uppercase font-futura-light'>
          <h3 className='my-2 text-lg font-futura-med'>Recursos</h3>
          <ul className='leading-6'>
            <li>
              <a href='https://calculadora.odigos.mx'>Calculadora Hipotecaria</a>
            </li>
            <li>
              <a href='https://www.odigos.blog/'>Blog</a>
            </li>
          </ul>
        </div>
        <div className='text-sm text-center uppercase font-futura-light'>
          <h3 className='my-2 text-lg font-futura-med'>Políticas</h3>
          <ul className='leading-6'>
            <li>
              <Link href='/politicas/terminos-y-condiciones'>
                <a>Términos y Condiciones</a>
              </Link>
            </li>
            <li>
              <Link href='/politicas/politicas-de-privacidad'>
                <a>Políticas de Privacidad</a>
              </Link>
            </li>
          </ul>
        </div>
        <div className='text-sm text-center uppercase font-futura-light'>
          <h3 className='my-2 text-lg font-futura-med'>Servicios</h3>
          <ul className='leading-6'>
            <li>
              <Link href='/login'>
                <a>Log In Agentes</a>
              </Link>
            </li>
            <li>
              <Link href='/register'>
                <a>Registro de Agentes</a>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className='hidden md:block w-11/12 h-[1px] bg-white self-center my-4 '></div>
      <div className='grid self-center w-11/12 mb-6 md:grid-cols-3'>
        <div className='flex flex-wrap items-center justify-center gap-3 text-white md:flex-col'>
          <h3 className='my-2 text-lg uppercase sm:text-xl font-futura-med'>Stay Tunned</h3>
          <label
            htmlFor='newsletter'
            className='flex items-center gap-2 px-3 py-1 mb-2 text-center bg-white border rounded-lg border-primary text-primary'
          >
            <div></div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              viewBox='125.145 635.777 28.781 18.707'
              width='25pt'
              height='15pt'
            >
              <path
                d=' M 143.594 646.592 L 141.66 648.278 C 140.457 649.325 138.636 649.323 137.433 648.278 L 135.499 646.603 L 127.078 654.473 C 127.151 654.48 127.229 654.484 127.303 654.484 L 151.767 654.484 C 151.838 654.484 151.911 654.479 151.981 654.473 L 143.594 646.592 Z  M 153.903 637.632 L 145.235 645.175 L 153.757 653.169 C 153.866 652.91 153.926 652.624 153.926 652.325 L 153.926 637.935 C 153.926 637.833 153.917 637.73 153.904 637.632 L 153.903 637.632 Z  M 125.167 637.632 C 125.154 637.73 125.145 637.833 125.145 637.935 L 125.145 652.325 C 125.145 652.621 125.207 652.902 125.314 653.157 L 133.858 645.175 L 125.168 637.632 L 125.167 637.632 Z  M 127.303 635.777 C 127.021 635.777 126.752 635.836 126.505 635.934 L 138.849 646.659 C 139.287 647.04 139.807 647.039 140.243 646.659 L 152.566 635.934 C 152.318 635.836 152.05 635.777 151.767 635.777 L 127.303 635.777 Z '
                fill='currentColor'
              />
            </svg>
            <input name='newsletter' id='newsletter' type='text' placeholder='ingresa tu email' className='ml-2 outline-none' />
          </label>
        </div>
        <div className='block md:hidden w-11/12 h-[1px] bg-white self-center my-4 '></div>
        <div className='flex items-center justify-center p-2'>
          <div>
            <img src='/images/Odigós Blanco.png' alt='Company Logo' className='max-w-[10rem]' />
          </div>
        </div>
        <div className='flex items-center justify-between p-2 text-white'>
          <h2 className='text-lg font-futura'>Follow us:</h2>
          <a target='_blank' className='block transition-transform transform hover:scale-105' href='https://www.instagram.com/odigosmx/ '>
            <InstagramIcon className='h-8 text-white md:w-auto' />
          </a>
          <a target='_blank' className='block transition-transform transform hover:scale-105' href='https://www.tiktok.com/@odigosmx'>
            <TickTokIcon className='h-8 text-white md:w-auto' />
          </a>
          <a
            target='_blank'
            className='block transition-transform transform hover:scale-105'
            href='https://www.youtube.com/channel/UCr0aoVDi3DrCzWWo5-2KAmQ'
          >
            <YoutubeIcon className='h-8 text-white md:w-auto' />
          </a>
          <a target='_blank' className='block transition-transform transform hover:scale-105' href='https://www.facebook.com/OdigosMx'>
            <FacebookIcon className='h-8 text-white md:w-auto' />
          </a>
          <a target='_blank' className='block transition-transform transform hover:scale-105' href='https://twitter.com/OdigosMx  '>
            <TwitterIcon className='h-8 text-white md:w-auto' />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
